/* This stylesheet generated by Transfonter (https://transfonter.org) on February 13, 2018 8:55 AM */

@font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-Hairline.eot');
    src: local('Lato Hairline'), local('Lato-Hairline'),
    url('Lato-Hairline.eot?#iefix') format('embedded-opentype'),
    url('Lato-Hairline.woff') format('woff'),
    url('Lato-Hairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Heavy.eot');
    src: local('Lato Heavy'), local('Lato-Heavy'),
    url('Lato-Heavy.eot?#iefix') format('embedded-opentype'),
    url('Lato-Heavy.woff') format('woff'),
    url('Lato-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-HeavyItalic.eot');
    src: local('Lato Heavy Italic'), local('Lato-HeavyItalic'),
    url('Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-HeavyItalic.woff') format('woff'),
    url('Lato-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.eot');
    src: local('Lato Regular'), local('Lato-Regular'),
    url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('Lato-Regular.woff') format('woff'),
    url('Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Black.eot');
    src: local('Lato Black'), local('Lato-Black'),
    url('Lato-Black.eot?#iefix') format('embedded-opentype'),
    url('Lato-Black.woff') format('woff'),
    url('Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-SemiboldItalic.eot');
    src: local('Lato Semibold Italic'), local('Lato-SemiboldItalic'),
    url('Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-SemiboldItalic.woff') format('woff'),
    url('Lato-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-BlackItalic.eot');
    src: local('Lato Black Italic'), local('Lato-BlackItalic'),
    url('Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-BlackItalic.woff') format('woff'),
    url('Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-HairlineItalic.eot');
    src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
    url('Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-HairlineItalic.woff') format('woff'),
    url('Lato-HairlineItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-MediumItalic.eot');
    src: local('Lato Medium Italic'), local('Lato-MediumItalic'),
    url('Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-MediumItalic.woff') format('woff'),
    url('Lato-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-ThinItalic.eot');
    src: local('Lato Thin Italic'), local('Lato-ThinItalic'),
    url('Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-ThinItalic.woff') format('woff'),
    url('Lato-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Thin.eot');
    src: local('Lato Thin'), local('Lato-Thin'),
    url('Lato-Thin.eot?#iefix') format('embedded-opentype'),
    url('Lato-Thin.woff') format('woff'),
    url('Lato-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Medium.eot');
    src: local('Lato Medium'), local('Lato-Medium'),
    url('Lato-Medium.eot?#iefix') format('embedded-opentype'),
    url('Lato-Medium.woff') format('woff'),
    url('Lato-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Italic.eot');
    src: local('Lato Italic'), local('Lato-Italic'),
    url('Lato-Italic.eot?#iefix') format('embedded-opentype'),
    url('Lato-Italic.woff') format('woff'),
    url('Lato-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Semibold.eot');
    src: local('Lato Semibold'), local('Lato-Semibold'),
    url('Lato-Semibold.eot?#iefix') format('embedded-opentype'),
    url('Lato-Semibold.woff') format('woff'),
    url('Lato-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.eot');
    src: local('Lato Bold'), local('Lato-Bold'),
    url('Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('Lato-Bold.woff') format('woff'),
    url('Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-BoldItalic.eot');
    src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
    url('Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-BoldItalic.woff') format('woff'),
    url('Lato-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-LightItalic.eot');
    src: local('Lato Light Italic'), local('Lato-LightItalic'),
    url('Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('Lato-LightItalic.woff') format('woff'),
    url('Lato-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Light.eot');
    src: local('Lato Light'), local('Lato-Light'),
    url('Lato-Light.eot?#iefix') format('embedded-opentype'),
    url('Lato-Light.woff') format('woff'),
    url('Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Black.ttf');
    src: local('Montserrat-Black'),
    url('Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-BlackItalic.ttf');
    src: local('Montserrat-BlackItalic'),
    url('Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Bold.ttf');
    src: local('Montserrat-Bold'),
    url('Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-BoldItalic.ttf');
    src: local('Montserrat-BoldItalic'),
    url('Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ExtraBold.ttf');
    src: local('Montserrat-ExtraBold'),
    url('Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ExtraBoldItalic.ttf');
    src: local('Montserrat-ExtraBoldItalic'),
    url('Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ExtraLight.ttf');
    src: local('Montserrat-ExtraLight'),
    url('Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 250;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ExtraLightItalic.ttf');
    src: local('Montserrat-ExtraLightItalic'),
    url('Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 250;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Light.ttf');
    src: local('Montserrat-Light'),
    url('Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Italic.ttf');
    src: local('Montserrat-Italic'),
    url('Montserrat-Italic.ttf') format('truetype');
    font-weight: 275;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-LightItalic.ttf');
    src: local('Montserrat-LightItalic'),
    url('Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.ttf');
    src: local('Montserrat-Medium'),
    url('Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-MediumItalic.ttf');
    src: local('Montserrat-MediumItalic'),
    url('Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.ttf');
    src: local('Montserrat-Regular'),
    url('Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBold.ttf');
    src: local('Montserrat-SemiBold'),
    url('Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBoldItalic.ttf');
    src: local('Montserrat-SemiBoldItalic'),
    url('Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Thin.ttf');
    src: local('Montserrat-Thin'),
    url('Montserrat-Thin.ttf') format('truetype');
    font-weight: 250;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ThinItalic.ttf');
    src: local('Montserrat-ThinItalic'),
    url('Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 250;
    font-style: italic;
}