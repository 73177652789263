.favoriteContainer {
    position: absolute;
    z-index: 10;
}

@media (max-width: 600px) {
    .favoriteContainer {
    top: 5px;
    right: 5px;
}
}

@media (min-width: 601px) {
    .favoriteContainer {
        top: 5px;
        right: 5px;
    }
}

.person {
    display: flex;
    justify-content: space-around;
    width: 100%
}

@media (max-width: 1400px){
    .person.favs {
        flex-direction: column;
    }
    .person.favs img.avatar {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 600px) {
    .person {
        flex-direction: column;
    }
    .person img.avatar {
        width: 50px;
        height: 50px;
    }
}

@media (min-width: 355px) and (max-width: 576px) {
    .person.favs {
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
    }
    .person.favs img.avatar {
        width: 20px;
        height: 20px;
    }
}