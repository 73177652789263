@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.check-icon {
  fill: #146492;
}

.copy-icon,
.edit-icon,
.trash-icon,
.choose-icon {
  fill: #000;
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

p,
label,
span,
a,
form,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl {
  line-height: normal;
  margin-bottom: 0px;
}

html,
body,
#root {
  height: 100%;
}

body {
  overflow-x: hidden;
}

.root-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.root-wrapper header {
  flex: 0 0 auto;
  position: sticky;
}

.root-wrapper__content {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.root-wrapper footer {
  flex: 0 0 auto;
}

/*****************
SCROLL STYLIZATION
******************/
html {
  scrollbar-color: #e0e0e0 transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

::-webkit-scrollbar:hover,
::-webkit-scrollbar:active {
  background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background-color: #198ACB;
  border-radius: 10px;
  transition: 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #198ACB;
}

::-webkit-scrollbar-thumb:active {
  background-color: #198ACB;
}

::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

/* mixins */
/* FORMS CUSTOMIZATION */
textarea {
  min-height: 200px;
}

textarea::-webkit-resizer {
  transform: translate(-5px, -5px);
  border-radius: 0.15rem;
  border: 8px solid transparent;
  border-right: 8px solid #e0e0e0;
  border-bottom: 8px solid #e0e0e0;
  transition: border 0.3s ease;
}

textarea:focus-visible::-webkit-resizer,
textarea:hover::-webkit-resizer,
textarea:focus::-webkit-resizer {
  border: 8px solid transparent;
  border-right: 8px solid rgba(20, 100, 146, 1);
  border-bottom: 8px solid rgba(20, 100, 146, 1);
}

input,
textarea,
.inp {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0.3em;
  padding: 0.5em 0.85em;
  width: 100%;
  line-height: normal;
  box-shadow: unset;
  outline: none;
}

input:not(:placeholder-shown),
textarea:not(:placeholder-shown),
.inp:not(:placeholder-shown) {
  border: 1px solid #bdbdbd;
}

input:hover,
textarea:hover,
.inp:hover {
  border: 1px solid #828282;
}

input:focus,
textarea:focus,
.inp:focus {
  border: 1px solid #146492;
}

input[type="number"] {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: calc(100% - 0.5em) center;
  padding: 0.5em 0.5em 0.5em 0.5em;
  width: 100px;
  max-width: 100%;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].price {
  background-image: url(bg-imgs/rub.svg);
  padding: 0.5em 1.5em 0.5em 0.5em !important;
}

input[type="number"].area {
  background-image: url(bg-imgs/м2.svg);
  padding: 0.5em 1.5em 0.5em 0.5em;
}

input[type="number"].area.landArea {
  background-image: none;
}

input[type="number"].ceilingHeight {
  background-image: url(bg-imgs/м.svg);
  padding: 0.5em 1.5em 0.5em 0.5em;
}

input[type="number"].length {
  background-image: url(bg-imgs/м.svg);
  padding: 0.5em 1.5em 0.5em 0.5em;
}

input[type="number"].percent {
  background-image: url(bg-imgs/percent.svg);
  padding: 0.5em 1.5em 0.5em 0.5em;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border: none;
  width: 1.3em;
  height: 1.3em;
  background-color: transparent;
  background: url(bg-imgs/radio.svg) no-repeat center;
  background-size: contain;
  padding: 0px;
}

input[type="radio"]:checked {
  background: url(bg-imgs/radio-ch.svg) no-repeat center;
  background-size: contain;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: 16px;
  height: 16px;
  background-color: transparent;
  padding: 0px;
  border-radius: 2px;
  box-sizing: content-box;
}

input[type="checkbox"]:checked {
  background: url(bg-imgs/mark2.svg) no-repeat center;
  background-size: auto;
}

label {
  display: flex;
  align-items: center;
}

label input:checked+span {
  color: #146492;
}

label span {
  flex: 1;
  max-width: calc(100% - 1.3em - 0.5rem);
}

label.inp-text div {
  padding: 0.5em 1em;
  background-color: #c59c7e;
  color: #fff;
  border-radius: 2em;
}

label.inp-text input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

label.inp-text input:checked+div {
  background-color: #146492;
}

.inp-btn {
  position: relative;
}

.inp-btn div {
  position: relative;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0.2em 0.6em;
  line-height: normal;
  z-index: 2;
}

.inp-btn input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.inp-btn input:checked+div {
  background-color: #146492;
  color: #fff;
}

input.articleInput {
  background: url(bg-imgs/search.svg) no-repeat 0.4em center;
  background-size: 1.5em;
  padding: 0.5em 1.2em 0.5em 2em;
  margin: 0;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: fit-content;
  outline: none;
  border: none;
  line-height: normal;
  background: url(bg-imgs/caret-down.svg) no-repeat right center;
  background-size: 1em 0.5em;
  padding: 0 1.2em 0 0;
}

.input-file {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 2.5em;
  width: 2.5em;
  padding: 0.3em;
  border-radius: 0.25em;
  transition: 0.2s;
  cursor: pointer;
}

.input-file:hover {
  background-color: #c59c7e;
}

.input-file:hover img {
  filter: brightness(0) invert(1);
}

.input-file input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 1px;
  height: 1px;
  top: 0px;
  left: 0px;
  padding: 0px;
}

.input-file img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  transition: 0.2s;
}

.input-file .ind {
  position: absolute;
  bottom: -0.5em;
  right: -0.25em;
  width: 1.2em;
  height: 1.2em;
  font-size: 0.9em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
  color: #146492;
  font-weight: 600;
}

.input-file .ind:empty {
  display: none;
}

.upload__image-wrapper {
  border: 1px dashed #e0e0e0;
  padding: 1em;
  border-radius: 0.25em;
  min-width: fit-content;
  max-width: 100%;
}

.upload__image-wrapper .imgs-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.75em;
  width: 100%;
}

.upload__image-wrapper .imgs-box:empty {
  min-width: 140px;
  min-height: 100px;
  background: url(bg-imgs/photo.svg) no-repeat;
  background-size: contain;
  background-position: center center;
}

.upload__image-wrapper .imgs-box .image-item {
  position: relative;
  width: 15.6666666667%;
  margin: 0 1% 1% 0;
}

@media (max-width: 1399px) {
  .upload__image-wrapper .imgs-box .image-item {
    width: 19%;
  }
}

@media (max-width: 1199px) {
  .upload__image-wrapper .imgs-box .image-item {
    width: 24%;
  }
}

@media (max-width: 575px) {
  .upload__image-wrapper .imgs-box .image-item {
    width: 32.3333333333%;
  }
}

/*.upload__image-wrapper .imgs-box .image-item.video {*/
/*  padding: 10px;*/
/*}*/
.upload__image-wrapper .imgs-box .image-item .name,
.upload__image-wrapper .imgs-box .image-item video,
.upload__image-wrapper .imgs-box .image-item img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.upload__image-wrapper .imgs-box .image-item .name {
  overflow: hidden;
  overflow-wrap: break-word;
}

@media (max-width: 1659px) {

  .upload__image-wrapper .imgs-box .image-item .name,
  .upload__image-wrapper .imgs-box .image-item video,
  .upload__image-wrapper .imgs-box .image-item img {
    height: 90px;
  }
}

@media (max-width: 575px) {

  .upload__image-wrapper .imgs-box .image-item .name,
  .upload__image-wrapper .imgs-box .image-item video,
  .upload__image-wrapper .imgs-box .image-item img {
    height: 23vw;
  }
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.44);
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  opacity: 0;
  transition: 0.2s ease;
  color: #fff;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper:hover,
.upload__image-wrapper .imgs-box .image-item__btn-wrapper:focus,
.upload__image-wrapper .imgs-box .image-item__btn-wrapper.show {
  opacity: 1;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper button {
  width: 2em;
  height: 2em;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper button.main-img {
  width: 100%;
  font-size: 0.8em;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper button img {
  width: 1em;
  height: 1em;
  object-fit: contain;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper-in-response {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.44);
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  transition: 0.2s ease;
  color: #fff;
  opacity: 1;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper-in-response button {
  height: 2em;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper-in-response button.main-img {
  width: 100%;
  font-size: 0.8em;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper-in-response button img {
  width: 1em;
  height: 1em;
  object-fit: contain;
}

.upload__image-wrapper .imgs-box .image-item .mark {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.44);
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  z-index: 3;
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0.5em 2em;
  text-align: center;
  transition: 0.5s;
  width: fit-content;
  font-size: 1em;
  line-height: normal;
}

.btn-1 {
  background-color: hsl(202, 78%, 45%) !important;
  color: #fff !important;
}

.btn-1:hover,
.btn-1:focus,
.btn-1:active {
  color: #fff !important;
  background-color: hsl(202, 76%, 38%) !important;
}

.btn-1:disabled {
  color: #c4c4c4 !important;
  background-color: #7a7878 !important;
}

.btn-2 {
  background-color: #c59c7e !important;
  color: #fff !important;
}

.btn-2:hover,
.btn-2:focus,
.btn-2:active {
  color: #fff !important;
  background-color: #bc8c69 !important;
}

.btn-2:disabled {
  color: #c4c4c4 !important;
  background-color: #7a7878 !important;
}

.btn-2 svg line {
  stroke: #fff;
}

.btn-3 {
  background-color: #e0e0e0 !important;
  color: #828282 !important;
  font-weight: 500;
}

.btn-3:hover,
.btn-3:focus,
.btn-3:active {
  color: #fff !important;
  background-color: #146492 !important;
}

.btn-3:disabled {
  color: #c4c4c4 !important;
  background-color: #7a7878 !important;
}

.btn-rad2 {
  border-radius: 2em;
}

.btn-fav {
  width: 2.125em;
  height: 2.125em;
  border-radius: 50%;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.btn-fav svg {
  width: 1.375em;
  height: 1.375em;
  fill: none;
}

.btn-fav svg path {
  fill: #828282;
  transition: 0.2s ease-in-out;
}

.btn-fav:hover svg path,
.btn-fav:focus svg path {
  fill: #146492;
}

.btn-fav:active,
.btn-fav.clicked {
  background-color: #e63946;
}

.btn-fav:active svg path,
.btn-fav.clicked svg path {
  fill: #fff;
}

.btn-pin {
  width: 2.125em;
  height: 2.125em;
  border-radius: 50%;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-pin svg {
  width: 1em;
  height: 1.125em;
  fill: none;
}

.btn-pin svg path {
  fill: #146492;
}

.btn-notice {
  width: 2.125em;
  height: 2.125em;
  border-radius: 50%;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
}

.btn-notice svg {
  width: 1.3em;
  height: 1.3em;
  fill: none;
}

.btn-notice svg path.fill-color {
  fill: #828282;
  transition: 0.2s ease-in-out;
}

.btn-notice:hover svg path.fill-color,
.btn-notice:focus svg path.fill-color {
  fill: #146492;
}

.btn-notice.reported>svg>path.fill-color {
  fill: #146492;
}

button.reported>svg>path.fill-color {
  fill: #f00;
}

button.notreported>svg>path.fill-color {
  fill: #ccc;
}

button.notreported:hover svg>path.fill-color {
  fill: #f00;
}

h1,
h2,
h3 {
  font-family: Lato;
}

h1 {
  text-align: left;
  font-weight: 700;
  margin-bottom: 1em;
  font-size: 1.875em;
  color: #16445e;
}

h1.main {
  text-align: center;
  font-size: 3em;
  color: #146492;
  width: fit-content;
  background-color: #fff;
  padding: 0.1em 1em;
}

@media (max-width: 1199px) {
  h1.main {
    font-size: 2.5em;
    padding: 0.1em 0.5em;
  }
}

@media (max-width: 1199px) {
  h1.main {
    font-size: 2em;
  }
}

h2 {
  text-align: center;
  font-size: 2.25em;
  font-weight: 600;
  margin-bottom: 1em;
  color: #146492;
}

@media (max-width: 1199px) {
  h2 {
    font-size: 2em;
  }
}

h3 {
  text-align: left;
  font-size: 1.75em;
  font-weight: 700;
  margin-bottom: 1em;
  color: #146492;
}

@media (max-width: 1199px) {
  h3 {
    font-size: 1.5em;
  }
}

h4 {
  font-family: Lato;
  text-align: left;
  color: #545454;
  font-size: 1.5em;
  font-weight: 700;
}

h5 {
  font-family: Lato;
  text-align: left;
  font-size: 1.25em;
  font-weight: 500;
}

.title-font {
  font-family: Lato;
}

.fs-07 {
  font-size: 0.7em;
}

.fs-08 {
  font-size: 0.8em;
}

.fs-09 {
  font-size: 0.9em;
}

.fs-11 {
  font-size: 1.1em;
}

.fs-12 {
  font-size: 1.2em;
}

.fs-13 {
  font-size: 1.3em;
}

.fs-14 {
  font-size: 1.4em;
}

.fs-15 {
  font-size: 1.5em;
}

.fs-16 {
  font-size: 1.6em;
}

.fs-17 {
  font-size: 1.7em;
}

.fs-18 {
  font-size: 1.8em;
}

.fs-19 {
  font-size: 1.9em;
}

.fs-20 {
  font-size: 2em;
}

.fs-90 {
  font-size: 9em;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.fw-9 {
  font-weight: 900;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.gray-1 {
  color: #333333;
}

.gray-2 {
  color: #4f4f4f;
}

.gray-3 {
  color: #828282;
}

.gray-4 {
  color: #bdbdbd;
}

.color-1 {
  color: #198ACB;
}

.color-2 {
  color: #c59c7e;
}

.color-3 {
  color: #ececec;
}

.blue {
  color: #2d9cdb;
}

.word-b {
  word-break: break-all;
}

.bb-1 {
  border-bottom: 1px solid;
}

.shad-box {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.shad-box-2 {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2em;
}

.w-fit {
  width: fit-content;
}

.flex-1 {
  flex: 1;
}

@media (min-width: 1660px) {
  .container {
    width: 1596px;
    max-width: 1596px;
  }
}

.mb-6 {
  margin-bottom: 6em;
}

@media (max-width: 575px) {
  .mb-6 {
    margin-bottom: 4em;
  }
}

@media (min-width: 992px) {
  .position-lg-relative {
    position: relative !important;
  }
}

.show-phone {
  position: relative;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-phone .phone {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-phone button {
  position: relative;
  z-index: 1;
  width: 100%;
  transition: 0.2s;
}

.show-phone button.invisible {
  z-index: -1;
  opacity: 0;
}

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0px;
  border: none !important;
}

.dropdown-menu li a.dropdown-item {
  color: #146492 !important;
  padding: 0.5em 2em !important;
  transition: 0.2s;
}

.dropdown-menu li a.dropdown-item:hover,
.dropdown-menu li a.dropdown-item:focus,
.dropdown-menu li a.dropdown-item.active,
.dropdown-menu li a.dropdown-item:active {
  background-color: #ececec;
}

body {
  margin: 0;
  font-family: Lato, "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #545454;
  font-size: 16px;
  background-color: #fff;
}

@media (max-width: 991px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  body {
    font-size: 12px;
  }
}

header {
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1em 0;
  position: sticky;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: 1900;
}

@media (max-width: 1199px) {
  header {
    padding: 0.5em 0;
    height: 55px;
  }
}

@media (max-width: 991px) {
  header {
    height: 66px;
  }
}

@media (max-width: 575px) {
  header {
    height: 64px;
    padding: 0.75em 0;
  }
}

header .container {
  display: flex;
  align-items: center;
  position: relative;
}

header .container nav {
  display: flex;
  align-items: center;
}

header .container nav a {
  color: #c59c7e;
  padding: 0.5em 1em;
  font-size: 1.1em;
  font-weight: 400;
  transition: 0.2s;
}

header .container nav a.active,
header .container nav a:hover,
header .container nav a:focus {
  color: #146492;
}

@media (max-width: 1199px) {
  header .container nav a {
    padding: 0.5em;
  }
}

@media (max-width: 991px) {
  header .container {
    justify-content: space-between;
  }
}

header .logo {
  max-height: 38px;
  object-fit: scale-down;
  object-position: center;
}

@media (max-width: 575px) {
  header .custom-select {
    max-width: 80px;
  }
}

header .custom-select>button {
  font-size: 0.9em;
}

@media (max-width: 991px) {
  header .custom-select>button {
    font-size: 1.1em;
  }
}

header .custom-select>button svg {
  width: 1em;
  height: 1em;
}

header .custom-select>button svg line {
  stroke: #c59c7e;
}

@media (max-width: 575px) {
  header .btn {
    font-size: 0.9em;
  }
}

main {
  min-height: calc(100vh - 470px);
  position: relative;
}

@media (max-width: 1199px) {
  main {
    min-height: calc(100vh - 455px);
  }
}

@media (max-width: 991px) {
  main {
    min-height: calc(100vh - 415px);
  }
}

@media (max-width: 767px) {
  main {
    min-height: calc(100vh - 100px);
  }
}

@media (max-width: 575px) {
  main {
    min-height: calc(100vh - 98px);
  }
}

#sec-1 {
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-bottom: 5em;
  margin-bottom: -5em;
}

#sec-1::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 5em;
  background: rgba(36, 44, 49, 0.22);
  filter: blur(36px);
}

@media (max-width: 991px) {
  #sec-1 {
    height: 550px;
  }
}

@media (max-width: 767px) {
  #sec-1 {
    height: 500px;
  }
}

@media (max-width: 575px) {
  #sec-1 {
    height: 400px;
  }
}

#sec-1 .container {
  position: relative;
  z-index: 2;
}

/* SECTION 2 */
.tiles {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.tiles .tile {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: calc((100% - 150px) / 5);
  margin-bottom: 25px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.tiles .tile img {
  height: 135px;
  width: 135px;
  object-fit: scale-down;
  display: block;
  margin: 0 auto;
}

.tiles .tile .links {
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  position: relative;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1em 0;
}

.tiles .tile .links .title {
  font-size: 1.5em;
  font-weight: 500;
  color: #c59c7e;
  margin: 0 0.5em;
  transition: 0.3s;
  text-align: center;
}

.tiles .tile .links .services-links:hover {
  color: #146492;
}

.tiles .tile .links .hover-links {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.2s;
}

@media (max-width: 1399px) {
  .tiles .tile .links .hover-links {
    padding: 0.5em 0;
  }
}

.tiles .tile .links .hover-links div {
  margin: 0 0.5em;
}

.tiles .tile .links .hover-links div::before {
  content: "⦁";
  color: #146492;
  font-size: 1.5em;
}

.tiles .tile .links .hover-links div a {
  font-size: 1.5em;
  font-weight: 500;
  color: #146492;
  text-align: center;
}

@media (max-width: 1659px) {
  .tiles .tile .links .hover-links div a {
    font-size: 1.25em;
  }
}

.tiles .tile .links .hover-links div a:hover,
.tiles .tile .links .hover-links div a:focus {
  border-bottom: 1px solid;
}

.tiles .tile .links:hover .hover-links {
  opacity: 1;
}

.tiles .tile .click-links {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tiles .tile .click-links .titles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  color: #c59c7e;
  font-weight: 500;
  font-size: 1.4em;
}

@media (max-width: 575px) {
  .tiles .tile .click-links .titles {
    font-size: 1.2em;
  }
}

.tiles .tile .click-links .titles .title {
  margin: 0 0.5em;
}

.tiles .tile .click-links ul {
  padding-left: 1.5em;
}

.tiles .tile .click-links ul li {
  color: #146492;
  font-weight: 500;
  font-size: 1.3em;
  margin-bottom: 0.4em;
}

@media (max-width: 575px) {
  .tiles .tile .click-links ul li {
    margin-bottom: 0.5em;
    font-size: 1.1em;
  }
}

@media (max-width: 1659px) {
  .tiles .tile {
    height: 250px;
  }

  .tiles .tile .links {
    height: 90px;
  }

  .tiles .tile .links .title {
    font-size: 1.25em;
  }
}

@media (max-width: 1399px) {
  .tiles {
    justify-content: space-between;
  }

  .tiles .tile {
    height: 240px;
    width: calc((100% - 80px) / 5);
    margin-bottom: 20px;
  }

  .tiles .tile .links {
    height: 80px;
    padding: 0.5em 0;
  }
}

@media (max-width: 1199px) {
  .tiles {
    justify-content: center;
  }

  .tiles .tile {
    width: calc((100% - 80px) / 4);
    margin-left: 10px;
    margin-right: 10px;
  }

  .tiles .tile img {
    height: 100px;
    width: 100px;
    margin: 0 auto 15px;
  }
}

@media (max-width: 991px) {
  .tiles .tile {
    width: calc((100% - 60px) / 3);
  }

  .tiles .tile .hover-links {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .tiles .tile {
    width: calc((100% - 40px) / 2);
  }

  .tiles .tile .links {
    height: 70px;
  }
}

@media (max-width: 575px) {
  .tiles .tile {
    width: calc((100% - 20px) / 2);
    height: calc(30vw + 55px);
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 8px;
  }

  .tiles .tile img {
    height: 20vw;
    width: 20vw;
    margin: 0 auto 5px;
  }

  .tiles .tile .links {
    height: 55px;
    padding: 0.6em 0;
  }

  .tiles .tile .links a {
    margin: 0 0.3em;
  }
}

.phone-container {
  position: relative;
}

.phone-container>svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: auto;
}

.phone-container.no-edit>svg {
  right: 25px;
}

.phone-container>svg:hover {
  cursor: pointer;
}

/* card */
.card-mini {
  width: 100%;
  /* max-height: 490px; */
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  border-radius: 0.5rem;
}

.card-mini img {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-mini .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

/* .card-mini .address {
  text-transform: capitalize;
} */
.card-mini .address img {
  width: 0.875em;
}

.card-mini .text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  font-size: 0.9em;
  font-weight: 300;
}

.card-mini .btn-fav {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}

.labels {
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 10;
}

.labels div {
  color: #fff;
  padding: 0.1em 0.25em;
  margin-bottom: 0.2em;
  font-weight: 500;
  font-size: 0.95em;
  display: flex;
  align-items: center;
}

.labels div img {
  margin-right: 0.15em;
  width: 1.5em;
  height: 1.5em;
  object-fit: scale-down;
}

.labels div.vip {
  background-color: #146492;
}

.labels div.hot {
  background-color: #c59c7e;
}

.card-midi {
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  padding: 1em;
  align-items: flex-start;
  border-radius: 10px;
}

.card-midi .text {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 6em;
  overflow: hidden;
  font-size: 0.9em;
  font-weight: 300;
}

.card-midi .bottom-btns {
  display: flex;
  align-items: center;
}

.card-midi .bottom-btns>* {
  margin-right: 1em;
}

.card-midi .img-preview {
  margin-top: 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.card-midi .img-preview .wrap {
  width: calc((100% - 1em) / 3);
  position: relative;
}

.card-midi .img-preview .wrap:not(:first-child) {
  margin-left: 0.5em;
}

.card-midi .img-preview .wrap img {
  width: 100%;
  height: 90px;
  object-fit: cover;
}

@media (max-width: 1659px) {
  .card-midi .img-preview .wrap img {
    height: 80px;
  }
}

.card-midi .img-preview .wrap .extra {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  color: #fff;
  font-family: Lato;
  font-size: 0.9em;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0em;
}

.card-midi .author img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  object-fit: cover;
}

.card-midi .show-phone {
  position: relative;
  width: 160px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-midi .show-phone div {
  position: relative;
  z-index: 1;
}

.card-midi .show-phone button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.hover-slider {
  height: 280px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1659px) {
  .hover-slider {
    height: 240px;
  }
}

@media (max-width: 1399px) {
  .hover-slider {
    height: 270px;
  }
}

@media (max-width: 1199px) {
  .hover-slider {
    height: 240px;
  }
}

@media (max-width: 991px) {
  .hover-slider {
    height: 260px;
  }
}

@media (max-width: 767px) {
  .hover-slider {
    height: 200px;
  }
}

@media (max-width: 575px) {
  .hover-slider {
    height: 67vw;
  }
}

@media (max-width: 360px) {
  .hover-slider {
    height: 45vw;
  }
}

.hover-slider img {
  height: 280px;
  width: 100%;
  position: absolute;
  object-fit: cover;
}

@media (max-width: 1659px) {
  .hover-slider img {
    height: 240px;
  }
}

@media (max-width: 1399px) {
  .hover-slider img {
    height: 270px;
  }
}

@media (max-width: 1199px) {
  .hover-slider img {
    height: 240px;
  }
}

@media (max-width: 991px) {
  .hover-slider img {
    height: 260px;
  }
}

@media (max-width: 767px) {
  .hover-slider img {
    height: 200px;
  }
}

@media (max-width: 575px) {
  .hover-slider img {
    height: 67vw;
  }
}

@media (max-width: 360px) {
  .hover-slider img {
    height: 45vw;
  }
}

.hover-slider .swiper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.hover-slider .sld {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: 1s;
  opacity: 0;
  z-index: -1;
}

.hover-slider .sld img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.hover-slider .sld.active {
  opacity: 1;
}

.hover-slider .sld .extra {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Lato;
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-slider .swiper-pagination {
  bottom: 0;
}

.hover-slider .swiper-pagination .swiper-pagination-bullet {
  flex-grow: 1;
  margin: 0px;
  display: flex;
  align-items: flex-end;
  position: relative;
  background: none;
}

.hover-slider .swiper-pagination .swiper-pagination-bullet::after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 2px;
  right: 2px;
  height: 5px;
  background-color: #ececec;
  transition: 0.3s;
}

.hover-slider .swiper-pagination .swiper-pagination-bullet-active::after {
  background-color: #146492;
}

.hover-slider .box {
  position: relative;
  display: flex;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.hover-slider .box .section {
  flex-grow: 1;
  margin: 0px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.hover-slider .box .section::after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 2px;
  right: 2px;
  height: 5px;
  background-color: #ececec;
  transition: 0.3s;
}

.hover-slider .box .section.active::after {
  background-color: #146492;
}

#sec-3 {
  scroll-margin-top: 100px;
}

/* SECTION 5 */
#sec-5 {
  background: url(bg-imgs/bg2.png) no-repeat center bottom;
  background-size: 100% auto;
}

@media (max-width: 575px) {
  #sec-5 .info {
    font-size: 10px;
  }
}

article {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

article img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

@media (max-width: 1659px) {
  article img {
    height: 240px;
  }
}

@media (max-width: 1399px) {
  article img {
    height: 220px;
  }
}

@media (max-width: 1199px) {
  article img {
    height: 200px;
  }
}

@media (max-width: 767px) {
  article img {
    height: 160px;
  }
}

@media (max-width: 575px) {
  article img {
    height: 55vw;
  }
}

@media (max-width: 1659px) {
  article h2 {
    font-size: 2em;
  }
}

@media (max-width: 1399px) {
  article h2 {
    font-size: 1.75em;
  }
}

@media (max-width: 1199px) {
  article h2 {
    font-size: 1.5em;
  }
}

article .text {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 12em;
  overflow: hidden;
  font-size: 0.9em;
  font-weight: 300;
}

@media (max-width: 1399px) {
  article .text {
    -webkit-line-clamp: 8;
    line-height: 1.2em;
    height: 9.6em;
  }
}

@media (max-width: 1199px) {
  article .text {
    -webkit-line-clamp: 6;
    line-height: 1.2em;
    height: 7.2em;
  }
}

/* SECTION 6 - catalog */
.form-search {
  position: relative;
  z-index: 1800;
  --title-size: 1.5em;
  --size: 1.1em;
  display: grid;
  grid-template-columns: 160px 215px 270px auto 180px;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1em 0.5em;
  width: 100%;
}

@media (max-width: 1659px) {
  .form-search {
    --title-size: 1.4em;
  }
}

@media (max-width: 1399px) {
  .form-search {
    --title-size: 1.3em;
    grid-template-columns: 130px 180px 250px auto 160px;
  }
}

@media (max-width: 1199px) {
  .form-search {
    --title-size: 1.2em;
    --size: 1em;
    grid-template-columns: 120px 150px 190px auto 140px;
  }
}

@media (max-width: 991px) {
  .form-search {
    grid-template-columns: 30% 30% 4fr;
    grid-template-rows: repeat(3, 1fr);
    z-index: 1701;
  }
}

@media (max-width: 767px) {
  .form-search {
    grid-gap: 0.75em 0.5em;
  }
}

@media (max-width: 575px) {
  .form-search {
    --title-size: 1em;
    grid-gap: 0.5em 0.25em;
  }
}

.form-search .map-search {
  grid-column: 1/6;
  grid-row: 1/2;
  font-size: var(--size);
  display: flex;
  justify-content: flex-end;
}

.catalog__search {
  grid-column: 1/6;
}

@media (max-width: 991px) {
  .form-search .map-search {
    grid-column: 1/4;
    grid-row: 3/4;
    justify-content: space-between;
    align-items: center;
  }

  .form-search .map-search img {
    width: 1em;
  }

  .catalog__search {
    grid-column: initial;
  }
}


.form-search .sel-1 {
  grid-column: 1/2;
  grid-row: 2/3;
  width: 100%;
}

@media (max-width: 991px) {
  .form-search .sel-1 {
    grid-row: 1/2;
  }
}

.form-search .sel-1 .btn {
  font-size: var(--title-size);
}

.form-search .sel-1 label {
  font-size: var(--size);
}

.form-search .sel-2 {
  grid-column: 2/3;
  grid-row: 2/3;
  width: 100%;
}

@media (max-width: 991px) {
  .form-search .sel-2 {
    grid-row: 1/2;
  }
}

.form-search .sel-2 .btn {
  font-size: var(--title-size);
}

.form-search .sel-2 label {
  font-size: var(--size);
}

.form-search .sel-3 {
  grid-column: 3/4;
  grid-row: 2/3;
  width: 100%;
}

.form-search .sel-4 .btn {
  font-size: var(--title-size);
}

.form-search .sel-4 label {
  font-size: var(--size);
}

@media (max-width: 991px) {
  .form-search .sel-3 {
    grid-row: 1/2;
  }
}

.form-search .sel-3 .btn {
  width: 100%;
  font-size: var(--title-size);
}

.form-search .sel-3 label {
  font-size: var(--size);
}

.form-search input[type="search"],
.form-search .catalog__search input {
  margin: 0;
  position: relative;
  grid-column: 4/5;
  grid-row: 2/3;
  width: 100%;
  font-size: var(--title-size);
  overflow-y: auto;
}

@media (max-width: 991px) {

  .form-search input[type="search"],
  .form-search .catalog__search input {
    grid-column: 1/3;
    grid-row: 2/3;
  }
}

.form-search button[type="submit"] {
  grid-column: 5/6;
  grid-row: 2/3;
  width: 100%;
  font-size: var(--title-size);
}

@media (max-width: 991px) {
  .form-search button[type="submit"] {
    grid-column: 3/4;
    grid-row: 2/3;
  }
}

.form-search .popular-queries {
  position: relative;
  scroll-direction: horizontal;
  grid-column: 1/6;
  grid-row: 3/4;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 1.1em;
  -ms-overflow-style: none;
}

@media (max-width: 991px) {
  .form-search .popular-queries {
    display: none;
  }
}

.form-search .popular-queries div {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 10px;
  box-shadow: 10px 0 5px 2px white;
}

.form-search .popular-queries button {
  color: #146492;
  margin-left: 3em;
  font-weight: 500;
  border-bottom: 1px solid #146492;
  white-space: nowrap;
}

.btn-view svg rect {
  fill: #e0e0e0;
}

.btn-view:hover svg rect {
  fill: #146492;
}

.btn-filter.btn-collapse {
  display: flex;
  align-items: center;
}

.btn-filter.btn-collapse span::after {
  content: "Расширенный фильтр";
}

.btn-filter.btn-collapse svg {
  transition: 0.2s;
}

.btn-filter.btn-collapse[aria-expanded="true"] svg {
  transform: rotate(180deg);
}

.btn-filter.btn-collapse[aria-expanded="true"] span::after {
  content: "Свернуть фильтр";
}

/* SECTION 7 - card page */
#sec-7 .icon-mini {
  width: 1.25em;
  height: 1.25em;
  object-fit: scale-down;
}

#sec-7 .btns {
  display: flex;
}

#sec-7 .btns button {
  width: 2.2em;
  height: 2.2em;
  margin-right: 1em;
}

@media (max-width: 1659px) {
  #sec-7 .frame {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  #sec-7 .frame {
    font-size: 12px;
  }
}

#ReactSimpleImageViewer {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.75);
}

#ReactSimpleImageViewer .react-simple-image-viewer__next,
#ReactSimpleImageViewer .react-simple-image-viewer__previous,
#ReactSimpleImageViewer .react-simple-image-viewer__close {
  opacity: 0.4;
}

.author img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 1199px) {
  .author img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 575px) {
  .author img {
    width: 80px;
    height: 80px;
  }
}

.frame {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.column-2 {
  column-count: 2;
  column-gap: 150px;
  padding-right: 150px;
}

@media (max-width: 1659px) {
  .column-2 {
    column-gap: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 1399px) {
  .column-2 {
    column-gap: 80px;
    padding-right: 0px;
  }
}

@media (max-width: 991px) {
  .column-2 {
    column-gap: 50px;
  }
}

@media (max-width: 767px) {
  .column-2 {
    column-gap: 0px;
    column-count: 1;
  }
}

.specification {
  background-image: url(bg-imgs/line-slice.png);
  background-repeat: repeat-x;
  background-size: auto;
  background-position: 50% 0.6em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #545454;
}

.specification:not(:first-child) {
  margin-top: 1em;
}

.specification .left span {
  background-color: #fff;
  padding-right: 10px;
  font-weight: 700;
  color: black;
}

.specification .right {
  text-align: right;
}

.specification .right span {
  background-color: #fff;
  padding-left: 10px;
}

.mobile-btns {
  position: sticky;
  bottom: 50px;
  left: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

@media (max-width: 575px) {
  .mobile-btns {
    font-size: 10px;
  }
}

.card-page-top {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  z-index: 100;
}

.card-page-top .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-page-top .container div.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1;
  font-family: Lato;
  font-weight: 700;
  font-size: 1.5em;
  color: #146492;
}

.card-page-top .container button {
  margin-left: 1em;
}

.photo {
  position: relative;
}

.photo img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.photo.small img {
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
  border-radius: 50%;
}

.photo .indicator {
  position: absolute;
  bottom: 0.25em;
  right: 0.25em;
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background-color: transparent;
  outline: 2px solid transparent;
}

.photo .indicator.online {
  background-color: #15a672;
  outline: 2px solid #ffffff;
}

/* SECTION 8 - article page */
#sec-8 .article-full .article-page-img {
  width: 500px;
  float: left;
  margin: 0 1.5em 1.5em 0;
}

@media (max-width: 1659px) {
  #sec-8 .article-full .article-page-img {
    width: 450px;
  }
}

@media (max-width: 1399px) {
  #sec-8 .article-full .article-page-img {
    width: 350px;
  }
}

@media (max-width: 991px) {
  #sec-8 .article-full .article-page-img {
    width: 300px;
  }
}

@media (max-width: 767px) {
  #sec-8 .article-full .article-page-img {
    width: 100%;
    margin: 0 0 1.5em 0;
  }
}

#sec-8 .article-full .text {
  font-weight: 300;
  font-size: 1.2em;
}

#sec-8 .article-full .text p {
  margin-bottom: 1em;
}

@media (max-width: 767px) {
  #sec-8 .article-full {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

/* SECTION 9 - services */
.sec-9 .mobile-title {
  background-color: #146492;
  color: #fff;
  font-size: 1.5em;
}

.sec-9 .mobile-title .container {
  display: flex;
  align-items: center;
  text-align: center;
}

.sec-9 .mobile-title .container svg {
  width: 2em;
  height: 2em;
}

.sec-9 .mobile-title .container svg .fill {
  fill: #fff;
}

.sec-9 nav.service-nav a {
  font-size: 1.4em;
  font-weight: 500;
  color: #c59c7e;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 100%;
  padding: 1.5em 1em;
}

.sec-9 nav.service-nav a.big {
  font-size: 3em;
  padding: 1.5em;
  transition: 0.3s;
}

@media (max-width: 1659px) {
  .sec-9 nav.service-nav a.big {
    font-size: 2.5em;
  }
}

@media (max-width: 1399px) {
  .sec-9 nav.service-nav a.big {
    font-size: 2.25em;
  }
}

@media (max-width: 1199px) {
  .sec-9 nav.service-nav a.big {
    font-size: 2em;
  }
}

@media (max-width: 991px) {
  .sec-9 nav.service-nav a.big {
    font-size: 1.75em;
  }
}

.sec-9 nav.service-nav a.big:hover {
  color: #146492;
}

.sec-9 nav.service-nav a.big:hover .fill {
  fill: #146492;
}

.sec-9 nav.service-nav a.big:hover .stroke {
  stroke: #146492;
}

.sec-9 nav.service-nav a.big svg {
  transition: 0.3s;
}

.sec-9 nav.service-nav a.big svg path {
  transition: 0.3s;
}

.sec-9 nav.service-nav a.big span {
  margin-left: 1em;
  text-align: center;
}

@media (max-width: 1399px) {
  .sec-9 nav.service-nav a {
    font-size: 1em;
    padding: 1.25em 1em;
  }
}

.sec-9 nav.service-nav a span {
  word-break: break-word;
}

@media (max-width: 1659px) {
  .sec-9 nav.service-nav a span {
    font-size: 1em;
    margin-left: 1em;
  }
}

@media (max-width: 991px) {
  .sec-9 nav.service-nav a span {
    margin-left: 0.5em;
  }
}

.sec-9 nav.service-nav a img {
  width: 3.75em;
  height: 4.375em;
}

@media (max-width: 1399px) {
  .sec-9 nav.service-nav a img {
    width: 3em;
    height: 4em;
  }
}

@media (max-width: 1199px) {
  .sec-9 nav.service-nav a img {
    width: 2em;
    height: 3em;
  }
}

.sec-9 nav.service-nav a svg {
  width: 3.75em;
  height: 4.375em;
  fill: none;
}

@media (max-width: 1399px) {
  .sec-9 nav.service-nav a svg {
    width: 3em;
    height: 4em;
  }
}

@media (max-width: 1199px) {
  .sec-9 nav.service-nav a svg {
    width: 2em;
    height: 3em;
  }
}

.sec-9 nav.service-nav a svg .fill {
  fill: #c59c7e;
}

.sec-9 nav.service-nav a svg .stroke {
  stroke: #c59c7e;
  stroke-width: 3.5px;
}

.sec-9 nav.service-nav a.active {
  color: #146492;
}

.sec-9 nav.service-nav a.active svg .fill {
  fill: #146492;
}

.sec-9 nav.service-nav a.active svg .stroke {
  stroke: #146492;
  stroke-width: 3.5px;
}

.sec-9 .service-leftMenu {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.sec-9 .service-leftMenu .title {
  background-color: #146492;
  color: #fff;
  font-size: 1.5em;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
}

.sec-9 .service-leftMenu .title svg {
  width: 2em;
  height: 2em;
}

.sec-9 .service-leftMenu .title svg .fill {
  fill: #fff;
}

.user-card,
.response-card {
  padding: 2em;
  background: #ffffff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 2.5em auto calc(7.5em + 0.5rem);
  grid-gap: 1.25em 3em;
}

@media (max-width: 1659px) {

  .user-card,
  .response-card {
    grid-gap: 1.25em 2em;
  }
}

@media (max-width: 1399px) {

  .user-card,
  .response-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 0;
  }
}

@media (max-width: 1199px) {

  .user-card,
  .response-card {
    padding: 1em;
  }
}

.user-card.page,
.response-card.page {
  display: block;
  padding: 2em 3em;
}

.user-card.page .title,
.response-card.page .title {
  flex-direction: row;
}

@media (max-width: 767px) {

  .user-card.page,
  .response-card.page {
    padding: 2em;
  }
}

@media (max-width: 575px) {

  .user-card.page,
  .response-card.page {
    padding: 1em;
  }
}

.user-card .photo,
.response-card .photo {
  grid-column: 1/2;
  grid-row: 1/3;
}

.user-card .photo img,
.response-card .photo img {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 1199px) {

  .user-card .photo img,
  .response-card .photo img {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 767px) {

  .user-card .photo img,
  .response-card .photo img {
    width: 120px;
    height: 120px;
  }
}

.user-card .btns,
.response-card .btns {
  grid-column: 1/2;
  grid-row: 3/4;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.user-card .title,
.response-card .title {
  grid-column: 2/5;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1399px) {

  .user-card .title,
  .response-card .title {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1199px) {

  .user-card .title,
  .response-card .title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {

  .user-card .title,
  .response-card .title {
    font-size: 13px;
  }
}

@media (max-width: 575px) {

  .user-card .title,
  .response-card .title {
    font-size: 12px;
  }
}

.user-card .desc,
.response-card .desc {
  grid-column: 2/5;
  grid-row: 2/3;
}

.user-card .desc .text,
.response-card .desc .text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-wrap: anywhere;
  line-height: 1.25em;
  height: 2.5em;
  font-weight: 300;
}

.user-card .serv-list,
.response-card .serv-list {
  grid-column: 2/5;
  grid-row: 3/4;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.user-card .serv-list .serv,
.response-card .serv-list .serv {
  background-color: #ececec;
  color: #828282;
  padding: 0.5em 1em;
  border-radius: 2em;
  font-weight: 500;
  margin-right: 0.75em;
  margin-bottom: 0.25em;
}

.response-card .btns {
  justify-content: start;
  gap: 10px;
}

.response-card_in {
  flex: auto;
  grid-template-rows: 2.5em auto 1fr;
}

.response-card_out {
  flex: auto;
  grid-template-rows: 2.5em auto 1.5rem;
}

.rating {
  font-size: 0.9em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1399px) {
  .rating {
    justify-content: center;
  }
}

.rating img {
  width: 1.5em;
  height: 1.5em;
}

.rating.small {
  justify-content: flex-start;
}

.rating.small img {
  width: 1.2em;
  height: 1.2em;
}

.rating img:not(:first-child) {
  margin-left: 0.2em;
}

.rating span {
  margin-left: 0.1em;
  color: #bdbdbd;
}

/* SECTION 10 - user page */
#sec-10 .user-photo {
  position: relative;
  width: 100%;
}

#sec-10 .user-photo img {
  width: 100%;
  height: 190px;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 1659px) {
  #sec-10 .user-photo img {
    height: 150px;
  }
}

@media (max-width: 575px) {
  #sec-10 .user-photo img {
    height: 37vw;
  }
}

#sec-10 .user-photo .indicator {
  position: absolute;
  bottom: 8%;
  right: 8%;
  width: 11%;
  height: 11%;
  border-radius: 50%;
  background-color: transparent;
  outline: 2px solid transparent;
}

#sec-10 .user-photo .indicator.online {
  background-color: #15a672;
  outline: 0.3em solid #ffffff;
}

.review {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
  padding: 3em;
  display: flex;
  position: relative;
}

@media (max-width: 767px) {
  .review {
    padding: 2em;
  }
}

@media (max-width: 575px) {
  .review {
    padding: 1.5em 1.5em 2em;
  }
}

.review img.photo {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 575px) {
  .review img.photo {
    width: 20vw;
    height: 20vw;
  }
}

.review .rating {
  justify-content: flex-start;
}

.review .date {
  position: absolute;
  top: 1em;
  right: 1.5em;
}

@media (max-width: 575px) {
  .review .date {
    top: 0.5em;
    right: 0.5em;
  }
}

.review .top {
  position: absolute;
  top: 1em;
  right: 1.5em;
  text-align: right;
}

@media (max-width: 575px) {
  .review .top {
    top: 0.5em;
    right: 0.5em;
  }
}

.review .btns {
  position: absolute;
  bottom: 1em;
  right: 2em;
}

@media (max-width: 575px) {
  .review .btns {
    bottom: 0.5em;
    right: 0.5em;
  }
}

.review .claiming {
  position: absolute;
  bottom: 1em;
  right: 2em;
}

@media (max-width: 575px) {
  .review .claiming {
    bottom: 0.5em;
    right: 0.5em;
  }
}

/*sprite with stars*/
.rate {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.rate input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: url(bg-imgs/sprite.svg) no-repeat;
  background-size: 100% auto;
  background-position: 0 0;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.2em;
}

.rate input:hover,
.rate input:hover~input,
.rate input:checked,
.rate input:checked~input {
  background: url(bg-imgs/sprite.svg) no-repeat;
  background-size: 100% auto;
  background-position: 0 -1.5em;
}

#write-review .photo img {
  width: 120px;
  height: 120px;
}

@media (max-width: 991px) {
  #write-review .photo img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 575px) {
  #write-review .photo img {
    width: 60px;
    height: 60px;
  }
}

#write-review .photo .indicator {
  bottom: 8%;
  right: 8%;
  width: 11%;
  height: 11%;
}

/* SECTION 11 - Submit an ad */
@media (max-width: 991px) {
  #sec-11.mb-6 {
    margin-bottom: 4em;
  }
}

@media (max-width: 767px) {
  #sec-11.mb-6 {
    margin-bottom: 3em;
  }
}

@media (max-width: 575px) {
  #sec-11.mb-6 {
    margin-bottom: 1.5em;
  }
}

#sec-11 .faster {
  position: relative;
  width: 100%;
  height: 270px;
  border-radius: 0.25em;
  overflow: hidden;
}

@media (max-width: 1659px) {
  #sec-11 .faster {
    height: 230px;
  }
}

@media (max-width: 1399px) {
  #sec-11 .faster {
    height: 210px;
  }
}

@media (max-width: 1199px) {
  #sec-11 .faster {
    height: 180px;
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  #sec-11 .faster {
    display: none;
  }
}

#sec-11 .faster img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#sec-11 .faster .title {
  position: absolute;
  top: 1em;
  left: 10%;
  width: 80%;
  z-index: 2;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  font-size: 1.5em;
}

@media (max-width: 1659px) {
  #sec-11 .faster .title {
    left: 5%;
    width: 90%;
  }
}

@media (max-width: 1399px) {
  #sec-11 .faster .title {
    font-size: 1.4em;
  }
}

#sec-11 .faster button {
  position: absolute;
  bottom: 1em;
  left: 5%;
  width: 90%;
  z-index: 2;
}

#sec-11 form.not-filled .title-req {
  color: #da1e2a;
}

#sec-11 form.not-filled input:not([type="radio"])[required],
#sec-11 form.not-filled textarea[required] {
  border: 1px solid rgba(218, 30, 42, 0.44);
}

#sec-11 form aside {
  position: sticky;
  top: 80px;
  left: 0px;
  width: 100%;
}

@media (max-width: 991px) {
  #sec-11 form aside nav.contents {
    position: sticky;
    top: 50px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    z-index: 100;
  }
}

#sec-11 form aside nav.contents ol {
  list-style: none;
  counter-reset: li;
  font-size: 1.5em;
  padding: 0;
}

@media (max-width: 1399px) {
  #sec-11 form aside nav.contents ol {
    font-size: 1.4em;
  }
}

@media (max-width: 1199px) {
  #sec-11 form aside nav.contents ol {
    font-size: 1.2em;
  }
}

@media (max-width: 991px) {
  #sec-11 form aside nav.contents ol {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
  }
}

#sec-11 form aside nav.contents ol li {
  padding-left: 2.25em;
  margin-bottom: 1em;
  position: relative;
}

@media (max-width: 1199px) {
  #sec-11 form aside nav.contents ol li {
    padding-left: 2em;
  }
}

@media (max-width: 991px) {
  #sec-11 form aside nav.contents ol li {
    padding-left: 0px;
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  #sec-11 form aside nav.contents ol li:not(:first-child) {
    margin-left: 1em;
  }
}

#sec-11 form aside nav.contents ol li:not(:first-child) a::after {
  content: "";
  position: absolute;
  left: calc(0.75em - 2px);
  bottom: 100%;
  width: 4px;
  height: 1em;
  background-color: #ececec;
}

@media (max-width: 991px) {
  #sec-11 form aside nav.contents ol li:not(:first-child) a::after {
    left: unset;
    right: 100%;
    bottom: calc(0.75em - 2px);
    height: 4px;
    width: 1em;
  }
}

#sec-11 form aside nav.contents ol li a {
  border-bottom: 4px solid transparent;
  transition: 0.2s;
  line-height: 1.5em;
}

@media (max-width: 991px) {
  #sec-11 form aside nav.contents ol li a {
    border-bottom: unset;
  }
}

#sec-11 form aside nav.contents ol li a::before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 2px solid #ececec;
  background-color: #ececec;
  color: #fff;
  font-family: Lato;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

#sec-11 form aside nav.contents ol li a.active,
#sec-11 form aside nav.contents ol li a:hover,
#sec-11 form aside nav.contents ol li a:focus,
#sec-11 form aside nav.contents ol li a:active {
  border-bottom: 4px solid #146492;
}

@media (max-width: 991px) {

  #sec-11 form aside nav.contents ol li a.active,
  #sec-11 form aside nav.contents ol li a:hover,
  #sec-11 form aside nav.contents ol li a:focus,
  #sec-11 form aside nav.contents ol li a:active {
    border-bottom: unset;
  }
}

#sec-11 form aside nav.contents ol li a.active:before,
#sec-11 form aside nav.contents ol li a:hover:before,
#sec-11 form aside nav.contents ol li a:focus:before,
#sec-11 form aside nav.contents ol li a:active:before {
  border: 2px solid #146492;
  background-color: #fff;
  color: #146492;
}

@media (max-width: 991px) {
  #sec-11 form aside nav.contents ol li a {
    width: 1.5em;
    height: 1.5em;
    display: block;
  }

  #sec-11 form aside nav.contents ol li a span {
    display: none;
  }
}

#sec-11 form aside nav.contents ol li.filled a::before {
  background-color: #146492;
  border: 2px solid #146492;
  color: transparent;
  background-image: url(bg-imgs/mark3.svg);
  background-repeat: no-repeat;
  background-position: 55% 55%;
  background-size: 0.8em;
}

#sec-11 form aside nav.contents ol li.filled a::after {
  background-color: #146492;
}

#sec-11 form .mob-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

@media (min-width: 992px) {
  #sec-11 form .mob-indicator {
    display: none;
  }
}

#sec-11 form .mob-indicator div {
  cursor: pointer;
  position: relative;
  font-size: 2em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 2px solid #ececec;
  background-color: #ececec;
  color: #fff;
  font-family: Lato;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 575px) {
  #sec-11 form .mob-indicator div {
    font-size: 1.5em;
  }
}

#sec-11 form .mob-indicator div.active {
  border: 2px solid #146492;
  background-color: #fff;
  color: #146492;
}

#sec-11 form .mob-indicator div:not(:first-child) {
  margin-left: 1em;
}

#sec-11 form .mob-indicator div:not(:first-child)::after {
  content: "";
  position: absolute;
  right: calc(100% + 2px);
  bottom: calc(0.75em - 4px);
  height: 4px;
  width: 1em;
  background-color: #ececec;
}

@media (max-width: 991px) {
  #sec-11 form fieldset[data-show="false"] {
    display: none;
  }

  #sec-11 form fieldset[data-show="true"] {
    display: block;
  }
}

@media (max-width: 767px) {

  #sec-11 form fieldset .title,
  #sec-11 form fieldset .title-req {
    font-size: 1.2em;
    font-weight: 500;
  }
}

@media (max-width: 991px) {
  #sec-11 form .frame {
    box-shadow: unset;
  }
}

/* SECTION 12 - personal PersonalAccount */
main.account {
  background: linear-gradient(white 100px, #F3F3F3 100px, #F3F3F3 820px, white 751px);
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@media (max-width: 1000px) {
  main.account {
    background: linear-gradient(white 160px, #F3F3F3 50px, #F3F3F3 820px, white 751px);
  }
}

@media (max-width: 786px) {
  main.account {
    background: linear-gradient(white 140px, #F3F3F3 50px, #F3F3F3 820px, white 751px);
  }
}

@media (max-width: 991px) {
  #sec-12 nav.menu {
    width: fit-content;
    margin: 0 auto;
  }
}

#sec-12 nav.menu ul {
  list-style: none;
  padding-left: 0px;
}

#sec-12 nav.menu ul li {
  display: flex;
  align-items: center;
}

#sec-12 nav.menu ul li:not(:last-child) {
  margin-bottom: 1.5em;
}

#sec-12 nav.menu ul li img {
  width: 1.5em;
  margin-right: 1em;
}

#sec-12 nav.menu ul li a,
#sec-12 nav.menu ul li button {
  font-family: Lato;
  font-size: 1.5em;
  font-weight: 600;
  color: #44494c;
}

#sec-12 nav.menu ul li a.active,
#sec-12 nav.menu ul li button.active {
  color: #146492;
}

@media (max-width: 1399px) {

  #sec-12 nav.menu ul li a,
  #sec-12 nav.menu ul li button {
    font-size: 1.25em;
  }
}

@media (max-width: 991px) {

  #sec-12 nav.menu ul li a,
  #sec-12 nav.menu ul li button {
    font-size: 1.75em;
  }
}

@media (max-width: 575px) {

  #sec-12 nav.menu ul li a,
  #sec-12 nav.menu ul li button {
    font-size: 1.5em;
    font-weight: 500;
  }
}

#sec-12 nav.menu ul li .count {
  width: 1.4em;
  height: 1.4em;
  background-color: #146492;
  color: #fff;
  font-family: Lato;
  font-weight: 600;
  font-size: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  border-radius: 50%;
}

#sec-12 .card-midi {
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.1));
}

#sec-12 input:not([type="checkbox"]):not([type="radio"]),
#sec-12 textarea,
#sec-12 .inp {
  padding: 0.8em 0.85em;
}

@media (max-width: 767px) {
  #sec-12 .review {
    padding: 2em 2em 3em 2em;
  }

  #sec-12 .review .top {
    top: 0.5em;
    right: 0.5em;
  }

  #sec-12 .review .top .rating img {
    width: 1em;
    height: 1em;
  }
}

.form-profile .upload__image-wrapper {
  border: unset;
  padding: 0px;
}

.form-profile .upload__image-wrapper .imgs-box .image-item {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}

.form-profile .upload__image-wrapper .imgs-box .image-item img {
  width: 100%;
  height: 100%;
}

.form-profile .upload__image-wrapper .imgs-box .image-item__btn-wrapper {
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-profile .upload__image-wrapper .imgs-box .image-item__btn-wrapper button {
  width: fit-content;
  display: flex;
}

.form-profile .upload__image-wrapper .imgs-box .image-item__btn-wrapper button img {
  width: 0.9em;
  height: 0.9em;
}

.input-tag {
  position: relative;
}

.input-tag input {
  position: relative;
  z-index: 1;
}

.input-tag button {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2em;
  z-index: 2;
  font-size: 1.5em;
  color: #c59c7e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
}

.tag-list .tag {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  padding: 0.6em 1em;
  border-radius: 2em;
  color: #828282;
  font-weight: 500;
  margin: 0 0.5em 0.75em 0;
}

.tag-list .tag button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
}

.conversations {
  height: calc(100vh - 184px);
  display: flex;
  flex-direction: column;
  margin-top: -48px;
}

.conversations h4 {
  background-color: #fff;
  position: relative;
  z-index: 99;
  padding: 1rem 0;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  margin: 0;
}

.conversations__list {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media all and (max-width: 1399px) {
  .conversations {
    margin-top: -24px;
  }
}

@media all and (max-width: 1199px) {
  .conversations {
    height: calc(100vh - 205px);
  }
}

@media all and (max-width: 991px) {
  .conversations {
    height: calc(100vh - 90px);
    margin-top: 0;
  }
}

@media all and (max-width: 767px) {
  .conversations {
    height: calc(100vh - 134px);
  }
}

@media all and (max-width: 575px) {
  .conversations {
    height: calc(100vh - 100px);
  }
}

.conversation-item {
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding: 2em 3em 2em 4em;
  display: flex;
  align-items: stretch;
  position: relative;
  font-size: 1.5rem
}

@media (max-width: 1399px) {
  .conversation-item {
    padding: 1.5em 2em;
  }
}

@media (max-width: 1199px) {
  .conversation-item {
    padding: 1em;
  }
}

@media (max-width: 991px) {
  .conversation-item {
    border: 1px solid #ececec;
  }
}

@media (max-width: 575px) {
  .conversation-item {
    margin-right: -12px;
    margin-left: -12px;
    align-items: flex-start;
  }
}

.conversation-item .link-to-chat {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.conversation-item.unread {
  background-color: #f1f7fa;
}

.conversation-item .photo {
  width: fit-content;
  margin-right: 2em;
  position: relative;
  z-index: 1;
}

@media (max-width: 1199px) {
  .conversation-item .photo {
    margin-right: 1em;
  }
}

.conversation-item .photo img {
  width: 115px;
  height: 115px;
}

@media (max-width: 767px) {
  .conversation-item .photo img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 575px) {
  .conversation-item .photo img {
    width: 75px;
    height: 75px;
  }
}

.conversation-item .photo .indicator {
  bottom: 8%;
  right: 8%;
  width: 11%;
  height: 11%;
  background-color: #bdbdbd;
  outline: 0.3em solid #ffffff;
}

@media (max-width: 575px) {
  .conversation-item .photo .indicator {
    width: 10px;
    height: 10px;
  }
}

.conversation-item .photo .indicator.online {
  background-color: #15a672;
  outline: 0.3em solid #ffffff;
}

.conversation-item .main {
  width: calc(100% - (115px + 10em));
  position: relative;
  z-index: 1;
}

@media (max-width: 1199px) {
  .conversation-item .main {
    width: calc(100% - (115px + 8em));
  }
}

@media (max-width: 767px) {
  .conversation-item .main {
    width: calc(100% - (100px + 8em));
  }
}

@media (max-width: 575px) {
  .conversation-item .main {
    width: calc(100% - (75px + 4em));
  }
}

.conversation-item .main .message {
  font-size: 0.9em;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-item .main .reply {
  font-size: 0.9em;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.conversation-item .main .reply img {
  width: 2em;
  height: 2em;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5em;
}

.conversation-item .main .reply .message {
  font-size: 0.9em;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 4.5em);
}

.conversation-item .main .reply .mark {
  width: 2em;
  background: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conversation-item .main .reply .mark i {
  font-size: 1.8em;
  color: #146492;
}

.conversation-item .main .reply .mark img {
  max-width: 100%;
  object-fit: scale-down;
  margin-right: 0px;
}

.conversation-item .new {
  margin-left: 1em;
  width: 2em;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 575px) {
  .conversation-item .new {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-left: 0px;
    font-size: 10px;
  }
}

.conversation-item .new div {
  font-size: 1.1em;
  font-family: Lato;
  font-weight: bold;
  background-color: #146492;
  color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conversation-item .end {
  margin-left: 2em;
  width: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 3;
}

@media (max-width: 1199px) {
  .conversation-item .end {
    margin-left: 1em;
  }
}

@media (max-width: 575px) {
  .conversation-item .end {
    height: 75px;
    margin-left: 0px;
  }
}

.conversation-item .end .btn-del {
  opacity: 0;
  transition: 0.2s ease;
}

@media (max-width: 1024px) {
  .conversation-item .end .btn-del {
    opacity: 1;
  }
}

.conversation-item .end .btn-del svg {
  width: 1.1em;
  height: 1.3em;
  fill: none;
}

.conversation-item .end .btn-del svg path {
  fill: #bdbdbd;
}

.conversation-item .end .btn-del i {
  font-size: 1.4em;
  color: #bdbdbd;
}

.conversation-item .end .btn-del:hover svg path,
.conversation-item .end .btn-del:focus svg path {
  fill: #146492;
}

.conversation-item .end .btn-del:hover i,
.conversation-item .end .btn-del:focus i {
  color: #146492;
}

.conversation-item .end .btn-del:active i {
  color: #c59c7e;
}

.conversation-item .end .btn-notice {
  background-color: transparent;
  opacity: 0;
  transition: 0.2s ease;
}

@media (max-width: 1024px) {
  .conversation-item .end .btn-notice {
    opacity: 1;
  }
}

.conversation-item .end .btn-notice svg {
  width: 1.5em;
  height: 1.5em;
}

.conversation-item .end .btn-notice i {
  font-size: 1.4em;
  color: #bdbdbd;
}

.conversation-item .end .btn-notice:hover i,
.conversation-item .end .btn-notice:focus i {
  color: #146492;
}

.conversation-item .end .btn-notice:active i {
  color: #c59c7e;
}

.conversation-item:hover {
  background-color: #f1f7fa;
}

.conversation-item:hover .end .btn-notice,
.conversation-item:hover .end .btn-del {
  opacity: 1;
}

.chat-top {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 0;
}

@media (max-width: 767px) {
  .chat-top {
    background-color: #fff;
    border-bottom: 1px solid #ececec;
  }
}

.chat-top a svg {
  width: 0.75em;
  height: 1.4em;
}

@media (max-width: 767px) {
  .chat-top a svg {
    width: 0.5em;
    height: 1em;
  }
}

.chat-top .photo img {
  width: 50px;
  height: 50px;
}

.chat-top .btns {
  display: flex;
  gap: 10px;
  height: 35px;
}

.chat-top .btns button {
  border: 2px solid #828282;
  border-radius: 30px;
  padding: 0.25em 2em;
}

.messages {
  position: relative;
  justify-content: flex-start;
  height: calc(100vh - 184px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: -48px;
  font-size: 1rem;
}

@media all and (max-width: 1399px) {
  .messages {
    margin-top: -24px;
  }
}

@media all and (max-width: 1199px) {
  .messages {
    height: calc(100vh - 205px);
  }
}

@media all and (max-width: 991px) {
  .messages {
    height: calc(100vh - 90px);
    margin-top: 0;
  }
}

@media all and (max-width: 767px) {
  .messages {
    height: calc(100vh - 134px);
  }
}

@media all and (max-width: 575px) {
  .messages {
    height: calc(100vh - 100px);
  }
}

.messages-list {
  background-color: transparent;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  flex-shrink: 1;
  flex-grow: 1;
}

@media all and (max-width: 991px) {
  .messages-list {
    padding: 15px 0;
  }
}

.messages-list_indent {
  margin-top: 50px;
}

.messages-list__loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.messages-list .chat-date {
  font-family: Lato;
  font-weight: 700;
  font-size: 1.2em;
  text-align: center;
  color: #828282;
  padding: 0.5em;
}

.messages-list .message {
  padding: 1.25em 1em 1.25em 4em;
  display: flex;
  align-items: flex-start;
  position: relative;
}

@media (max-width: 1399px) {
  .messages-list .message {
    padding: 1.25em 1em 1.25em 2em;
  }
}

@media (max-width: 991px) {
  .messages-list .message {
    padding: 1em 20% 1em 1em;
  }
}

@media (max-width: 575px) {
  .messages-list .message {
    padding: 0.5em 20% 0.5em 1em;
  }
}

.messages-list .message.editable {
  background-color: rgba(20, 100, 146, 0.15);
}

.messages-list .message.unread {
  background-color: #f1f7fa;
}

.messages-list .message.unread .photo .indicator,
.messages-list .message.unread .photo .indicator.online {
  outline: 0.3em solid #f1f7fa;
}

.messages-list .message .photo {
  width: fit-content;
  margin-right: 2em;
  position: relative;
  z-index: 1;
}

@media (max-width: 1199px) {
  .messages-list .message .photo {
    margin-right: 1em;
  }
}

@media (max-width: 991px) {
  .messages-list .message .photo {
    display: none;
  }
}

.messages-list .message .photo img {
  width: 115px;
  height: 115px;
}

@media (max-width: 1199px) {
  .messages-list .message .photo img {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 767px) {
  .messages-list .message .photo img {
    width: 60px;
    height: 60px;
  }
}

.messages-list .message .photo .indicator {
  bottom: 8%;
  right: 8%;
  width: 11%;
  height: 11%;
  background-color: #bdbdbd;
  outline: 0.3em solid #ffffff;
}

.messages-list .message .photo .indicator.online {
  background-color: #15a672;
  outline: 0.3em solid #ffffff;
}

.messages-list .message .main {
  border: unset !important;
  width: calc(100% - (115px + 11em));
}

@media (max-width: 1199px) {
  .messages-list .message .main {
    width: calc(100% - (90px + 10em));
  }
}

@media (max-width: 991px) {
  .messages-list .message .main {
    background-color: #f2f2f2;
    width: auto;
    padding: 1em;
    border-radius: 4px;
  }
}

.messages-list .message .main_mobile {
  position: relative;
  background-color: rgba(20, 100, 146, 0.5) !important;
  color: black !important;
  max-width: 200px;
}

.messages-list .message .main_mobile .text {
  font-weight: 500 !important;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  width: 100%;
  word-break: break-word;
}

.messages-list .message .main.selected {
  background-color: rgba(197, 156, 126, 0.3) !important;
}

.messages-list .message .main.active .main__dropdown {
  display: flex;
}

.messages-list .message .main .name {
  font-size: 1.3em;
  font-family: Lato;
  font-weight: 700;
  margin-bottom: 0.5em;
}

@media (max-width: 991px) {
  .messages-list .message .main .name {
    display: none;
  }
}

.messages-list .message .main .text {
  font-size: 0.9em;
  font-weight: 300;
}

.messages-list .message .main .text p {
  margin-bottom: 1em;
}

@media (max-width: 991px) {
  .messages-list .message.my {
    padding: 1em 1em 1em 20%;
  }

  .messages-list .message.my .main {
    background-color: #f1f7fa;
  }
}

@media (max-width: 575px) {
  .messages-list .message.my {
    padding: 0.5em 1em 0.5em 20%;
  }
}

.messages-list .message .mark {
  width: 2em;
  background: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages-list .message .mark img {
  max-width: 100%;
  object-fit: scale-down;
  margin-right: 0px;
}

@media (max-width: 991px) {
  .messages-list .message .mark img {
    height: 100%;
  }
}

.messages-list .message .mark i {
  font-size: 1.6em;
  color: #146492;
  line-height: 0.8em;
}

@media (max-width: 991px) {
  .messages-list .message .mark {
    position: absolute;
    bottom: 1.2em;
    right: 4.5em;
    height: 0.75em;
  }
}

.messages-list .message .date {
  font-size: 0.9em;
  font-weight: 500;
  width: 3em;
  margin-left: 1em;
}

@media (max-width: 991px) {
  .messages-list .message .date {
    position: absolute;
    bottom: 1.2em;
    right: calc(20% + 1em);
  }
}

@media (max-width: 991px) {
  .messages-list .message.my .date {
    right: 2em;
  }
}

.messages-list .message .btns {
  display: flex;
  justify-content: space-around;
  width: 4em;
  margin-left: 1em;
}

@media (max-width: 1199px) {
  .messages-list .message .btns {
    width: 3em;
  }
}

@media (max-width: 991px) {
  .messages-list .message .btns {
    display: none;
  }
}

.messages-list .message .btns button {
  width: 1em;
  height: 1em;
  opacity: 0;
  transition: 0.2s ease;
}

@media (max-width: 1024px) {
  .messages-list .message .btns button {
    opacity: 1;
  }
}

.messages-list .message .btns button svg {
  width: 1em;
  height: 1em;
  fill: none;
}

.messages-list .message .btns button svg path {
  transition: 0.2s ease;
  fill: #bdbdbd;
}

.messages-list .message .btns button i {
  font-size: 1.1em;
  color: #bdbdbd;
}

.messages-list .message .btns button:hover svg path,
.messages-list .message .btns button:focus svg path {
  fill: #146492;
}

.messages-list .message .btns button:hover i,
.messages-list .message .btns button:focus i {
  color: #146492;
}

.messages-list .message .btns button:active i {
  color: #c59c7e;
}

.messages-list .message:hover .btns button {
  opacity: 1;
}

.messages .send-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

.messages textarea {
  min-height: 100px;
}

@media (max-width: 575px) {
  .messages .send-message {
    left: 0;
    z-index: 10;
  }
}

.messages .send-message .input-file {
  width: 3em;
  height: 3em;
}

.messages .send-message button[type="submit"] img {
  width: 2em;
}

.messages__actions {
  position: absolute;
  left: 0;
  right: 0;
  top: 67px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  width: 100%;
  padding: 0 15px;
  background-color: white;
  border-top: 1px solid #ececec;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  transition: height 0.2s linear, opacity 0.2s linear, overflow 0.2s linear;
}

@media all and (max-width: 991px) {
  .messages__actions {
    top: 70px;
  }
}

@media all and (max-width: 765px) {
  .messages__actions {
    top: 71px;
  }
}

@media all and (max-width: 575px) {
  .messages__actions {
    top: 63px;
  }
}

.messages__actions.show {
  height: 50px;
  overflow: visible;
  opacity: 1;
}

.messages__actions .btns {
  display: flex;
  gap: 10px;
  height: 35px;
}

.messages__actions .btns button {
  padding: 0 30px;
  border: 2px solid #828282;
  border-radius: 30px;
}

.messages__actions .btns button:hover {
  background-color: rgba(20, 100, 146, 0.1);
}

.chat-actions-icon {
  width: 24px;
  height: 24px;
}

.choose-icon {
  width: 24px;
  height: 24px;
  fill: #146492;
}

.dropdown-toggle {
  padding: 0px 0.5em;
}

.dropdown-toggle::after {
  content: unset;
}

.dropdown-toggle svg {
  fill: none;
  width: 0.4em;
  height: 1.7em;
}

.dropdown-toggle svg circle {
  fill: #bdbdbd;
}

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0;
  max-width: 500px;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-menu .dropdown-item {
  color: #146492;
  padding: 0.5em 2em;
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
  background-color: transparent;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active {
  background-color: #ececec;
}

.tabs {
  list-style: none;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.tabs li:not(:first-child) {
  margin-left: 2em;
}

@media (max-width: 575px) {
  .tabs li:not(:first-child) {
    margin-left: 1em;
  }
}

.tabs li button {
  font-size: 1.25em;
  font-weight: 600;
  font-family: Lato;
  border-bottom: 4px solid transparent;
}

.tabs li button:hover,
.tabs li button:focus,
.tabs li button :active {
  color: #146492;
}

.tabs li button.active {
  color: #146492;
  border-bottom: 4px solid #c59c7e;
}

.form-response {
  font-size: 1.1em;
}

@media (max-width: 1399px) {
  .form-response {
    font-size: 1em;
  }
}

@media (max-width: 1199px) {
  .form-response {
    font-size: 0.9em;
  }
}

.form-response input[type="number"] {
  padding: 1em 1.5em !important;
  min-width: 200px;
}

@media (max-width: 1399px) {
  .form-response input[type="number"] {
    padding: 0.5em 1em !important;
    min-width: 100%;
  }
}

.form-response .custom-select {
  width: fit-content;
}

@media (max-width: 1399px) {
  .form-response .custom-select {
    width: 100%;
    margin-top: 0.5em;
  }
}

.form-response .custom-select .btn {
  padding: 1em !important;
}

@media (max-width: 1399px) {
  .form-response .custom-select .btn {
    padding: 0.5em 1em !important;
  }
}

/* SECTION 13 - hypotec */
.sec-13 .item {
  font-size: 1.3em;
}

@media (max-width: 1659px) {
  .sec-13 .item {
    font-size: 1.1em;
  }
}

@media (max-width: 1399px) {
  .sec-13 .item {
    font-size: 1em;
  }
}

@media (max-width: 575px) {
  .sec-13 .item {
    font-size: 0.8em;
  }
}

.sec-13 .item .img {
  width: 100%;
  height: 180px;
  object-fit: scale-down;
  margin-bottom: 1em;
}

@media (max-width: 1659px) {
  .sec-13 .item .img {
    height: 150px;
  }
}

@media (max-width: 1399px) {
  .sec-13 .item .img {
    height: 125px;
  }
}

@media (max-width: 575px) {
  .sec-13 .item .img {
    height: 25vw;
    margin-bottom: 0.5em;
  }
}

.sec-13 .item .link {
  color: #2d9cdb;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 2.4em;
  word-break: break-all;
}

/* Login & Registration */
form.entrance {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 3em 20% 4em;
}

form.entrance input:not([type="checkbox"]):not([type="radio"]),
form.entrance textarea,
form.entrance .inp {
  padding: 0.8em 0.85em;
}

@media (max-width: 1399px) {
  form.entrance {
    padding: 3em 15% 4em;
  }
}

@media (max-width: 1199px) {
  form.entrance {
    padding: 2em 3em 3em;
  }
}

.password {
  position: relative;
}

.password input {
  position: relative;
  z-index: 1;
}

.password button {
  position: absolute;
  top: 0px;
  right: 1em;
  height: 100%;
  z-index: 2;
  width: 2.5em;
}

.password button img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

footer {
  background-color: #373c3f;
  color: #fff;
  height: 400px;
  padding: 3em 0;
}

@media (max-width: 991px) {
  footer {
    height: 365px;
  }
}

@media (max-width: 767px) {
  footer {
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    padding: 0;
  }
}

footer hr {
  height: 2px;
  width: 100%;
  background-color: #fff;
  opacity: 1;
  border: none;
  margin: 1.75em 0;
}

@media (max-width: 1199px) {
  footer hr {
    margin: 1.5em 0;
    height: 1px;
  }
}

footer .logo {
  display: block;
  max-width: 100%;
  margin: 0 auto 1em;
}

@media (max-width: 1199px) {
  footer .logo {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin: 0 1em 0 0;
  }
}

footer nav.desktop ul {
  list-style: none;
  padding: 0px;
}

footer nav.desktop ul li:not(:first-child) {
  margin-top: 1.5em;
}

@media (max-width: 1199px) {
  footer nav.desktop ul li:not(:first-child) {
    margin-top: 1em;
  }
}

footer nav.desktop ul li a {
  text-transform: uppercase;
  font-family: Lato;
  font-size: 1.25em;
  font-weight: 500;
}

@media (max-width: 1199px) {
  footer nav.desktop ul li a {
    font-size: 1em;
  }
}

footer nav.mobile {
  height: 100%;
}

footer nav.mobile ul {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  padding: 0px;
}

footer nav.mobile ul li a svg {
  fill: none;
}

footer nav.mobile ul li a svg path {
  fill: #c4c4c4;
}

footer nav.mobile ul li a svg.type-2 path {
  fill: none;
  stroke: #c4c4c4;
  stroke-width: 3px;
  stroke-linecap: square;
  stroke-linejoin: round;
}

footer nav.mobile ul li a.heart.counter svg path,
footer nav.mobile ul li a.active.heart.counter svg path {
  fill: #E41313;
}

footer nav.mobile ul li a.active.heart svg path,
footer nav.mobile ul li a.active svg path {
  fill: #c59c7e;
}

footer nav.mobile ul li a.active svg.type-2 path {
  fill: none;
  stroke: #c59c7e;
}




/* SWIPER SLIDERS */
.swiper-4,
.swiper-3 {
  overflow: hidden;
  padding: 1em 0 3em !important;
}

.swiper-4 .swiper-button-prev,
.swiper-4 .swiper-button-next,
.swiper-3 .swiper-button-prev,
.swiper-3 .swiper-button-next {
  width: 1.5em;
  /* height: 3em; */
}

.swiper-4 .swiper-button-prev::after,
.swiper-4 .swiper-button-next::after,
.swiper-3 .swiper-button-prev::after,
.swiper-3 .swiper-button-next::after {
  content: unset;
}

@media (max-width: 1199px) {

  .swiper-4 .swiper-button-prev,
  .swiper-4 .swiper-button-next,
  .swiper-3 .swiper-button-prev,
  .swiper-3 .swiper-button-next {
    display: none;
  }
}

.swiper-4 .swiper-button-next,
.swiper-3 .swiper-button-next {
  right: -2em;
}

.swiper-4 .swiper-button-prev,
.swiper-3 .swiper-button-prev {
  left: -2em;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: unset;
}

.swiper-button-prev svg,
.swiper-button-next svg {
  width: 1.5em;
  height: 3em;
  fill: none;
}

.swiper-button-prev svg line,
.swiper-button-next svg line {
  stroke: #146492;
  stroke-width: 3px;
}

.swiper-pagination {
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 1em;
  height: 1em;
  background-color: #ececec;
  opacity: 1;
}

.swiper-pagination-bullet:not(:first-child) {
  margin-left: 0.75em;
}

.swiper-pagination-bullet-active {
  background-color: #c59c7e;
}

.swiper-container-fade .swiper-slide {
  opacity: 0 !important;
  transition: 0.1s;
  z-index: 1;
}

.swiper-container-fade .swiper-slide-active {
  opacity: 1 !important;
  z-index: 2;
}

.main-slider .swiper-button-prev svg line,
.main-slider .swiper-button-next svg line,
.thumbs-slider .swiper-button-prev svg line,
.thumbs-slider .swiper-button-next svg line {
  stroke: #c59c7e;
  stroke-width: 4px;
}

.main-slider {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
}

.main-slider .swiper-slide {
  width: 100%;
  height: 500px;
  position: relative;
}

@media (max-width: 1659px) {
  .main-slider .swiper-slide {
    height: 460px;
  }
}

@media (max-width: 1399px) {
  .main-slider .swiper-slide {
    height: 380px;
  }
}

@media (max-width: 1199px) {
  .main-slider .swiper-slide {
    height: 325px;
  }
}

@media (max-width: 991px) {
  .main-slider .swiper-slide {
    height: 380px;
  }
}

@media (max-width: 767px) {
  .main-slider .swiper-slide {
    height: 330px;
  }
}

@media (max-width: 575px) {
  .main-slider .swiper-slide {
    height: 60vw;
  }
}

.main-slider .swiper-slide button {
  position: absolute;
  top: 1em;
  right: 1em;
}

@media (max-width: 991px) {
  .main-slider .swiper-slide button {
    right: 2em;
  }
}

@media (max-width: 575px) {
  .main-slider .swiper-slide button {
    width: 1.5em;
  }
}

.main-slider-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.main-slider .swiper-button-prev {
  left: 1em;
}

.main-slider .swiper-button-next {
  right: 1em;
}

@media (max-width: 1199px) {

  .main-slider .swiper-button-prev,
  .main-slider .swiper-button-next {
    width: 1.5em;
  }
}

@media (max-width: 767px) {
  .main-slider .swiper-button-prev {
    left: 0em;
  }

  .main-slider .swiper-button-next {
    right: 0em;
  }

  .main-slider .swiper-button-prev,
  .main-slider .swiper-button-next {
    background-color: rgba(68, 73, 76, 0.28);
    width: 2em;
    padding: 0.5em;
    top: 0px;
    margin-top: 0px;
    bottom: 0px;
    height: 100%;
  }

  .main-slider .swiper-button-prev svg line,
  .main-slider .swiper-button-next svg line {
    stroke: #fff;
    stroke-width: 4px;
  }
}

.work-example {
  width: 100%;
  height: 90px;
  object-fit: cover;
}

@media (max-width: 575px) {
  .work-example {
    height: 16vw;
  }
}

.thumbs-slider {
  padding: 3px 3px;
  overflow: hidden;
}

.thumbs-slider .swiper-slide-thumb-active {
  outline: 3px solid #c59c7e;
}

.thumbs-slider .swiper-slide video,
.thumbs-slider .swiper-slide img {
  width: 100%;
  height: 77px;
  object-fit: cover;
}

@media (max-width: 575px) {

  .thumbs-slider .swiper-slide video,
  .thumbs-slider .swiper-slide img {
    height: 15vw;
  }
}

.thumbs-slider .swiper-button-prev {
  left: 0;
}

.thumbs-slider .swiper-button-next {
  right: 0;
}

@media (max-width: 1199px) {

  .thumbs-slider .swiper-button-prev,
  .thumbs-slider .swiper-button-next {
    width: 1.5em;
  }
}

@media (max-width: 767px) {

  .thumbs-slider .swiper-button-prev,
  .thumbs-slider .swiper-button-next {
    display: none;
  }
}

.swiper-home {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.swiper-home img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.swiper-home .swiper-pagination {
  bottom: 6.5em;
}

.swiper-container-fade .swiper-slide {
  transition: opacity 1s ease-in;
}

/* breadcrumbs */
.breadcrumb {
  font-size: 0.8em;
  margin-bottom: 0px;
}

/* pagination */
.pagination {
  justify-content: center;
}

.pagination .page-item {
  width: 2em;
  height: 1.9em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-item .page-link {
  width: 100%;
  height: 100%;
  color: #828282;
  border: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-item.active .page-link {
  background-color: #146492;
  color: #fff;
}

/* MODAL WINDOWS */
#header-menu nav ul {
  list-style: none;
  padding-left: 0px;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1999;
}

.modal .modal-content {
  background-color: #fff;
  border-radius: 0px;
  border: none;
}

.modal .modal-body {
  padding: 2em;
}

.modal__exit-with-account .modal-body {
  padding: 0 2em 2em 2em;
}

.modal-header {
  border: none;
}

.offcanvas {
  width: 450px;
  z-index: 2000;
}

@media (max-width: 575px) {
  .offcanvas {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .offcanvas.offcanvas-start {
    width: 700px;
  }
}

.offcanvas-backdrop {
  z-index: 1999;
}

@media screen and (min-width: 1400px) {
  .offcanvas-header {
    margin-bottom: 25px;
  }
}

.offcanvas-body {
  padding: 1em;
}

@media screen and (min-width: 576px) {
  .offcanvas-body {
    padding: 2em;
  }
}

@media screen and (min-width: 1200px) {
  .offcanvas-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .offcanvas-body>div {
    width: 40%;
    flex-grow: 1;
    max-width: 49%;
  }

  .offcanvas-body>div:nth-child(odd) {
    margin-right: 1rem;
  }
}

.offcanvas-body nav ul li {
  text-align: center;
}

.offcanvas-body nav ul li:not(:first-child) {
  margin-top: 1em;
}

.offcanvas-body nav ul li a {
  font-size: 1.3em;
  color: #c59c7e;
  text-align: center;
}

.offcanvas-body nav ul li a.active {
  color: #146492;
}

.offcanvas-footer {
  padding: 1em;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}

.offcanvas-ymap,
.offcanvas-cards {
  width: 450px !important;
  height: calc(100vh - 410px);
}

@media screen and (min-width: 992px) {

  .offcanvas-ymap,
  .offcanvas-cards {
    top: auto !important;
  }
}

@media (max-width: 1659px) {

  .offcanvas-ymap,
  .offcanvas-cards {
    height: calc(100vh - 350px);
  }
}

@media (max-width: 1399px) {

  .offcanvas-ymap,
  .offcanvas-cards {
    height: calc(100vh - 340px);
  }
}

@media (max-width: 1199px) {

  .offcanvas-ymap,
  .offcanvas-cards {
    height: calc(100vh - 310px);
  }
}

.offcanvas-ymap {
  z-index: 1700;
}

@media (max-width: 991px) {
  .offcanvas-ymap {
    display: none;
  }
}

@media (max-width: 991px) {
  .offcanvas-cards {
    height: 100vh;
    top: 0;
  }
}

@media (max-width: 767px) {
  .offcanvas-cards {
    height: calc(100vh - 50px);
    bottom: 50px;
  }
}

.offcanvas-catalog {
  z-index: 2001;
}

.offcanvas-menu__list {
  list-style: none;
  padding: 0;
}

.offcanvas-menu__list .icon {
  height: 21px;
  width: 21px;
}

.btn-close {
  position: absolute;
  z-index: 100 !important;
  top: 1em;
  right: 1em;
  width: 2em;
  height: 2em;
  padding: 0px;
  display: flex;
  opacity: 1;
  background: unset;
}

@media (max-width: 1399px) {
  .btn-close {
    top: 0.5em;
    right: 0.5em;
    width: 1.25em;
    height: 1.25em;
  }
}

/*@media (max-width: 767px) {*/
/*  .btn-close {*/
/*    top: 1em;*/
/*    right: 1em;*/
/*    width: 1em;*/
/*    height: 1em;*/
/*  }*/
/*}*/
.btn-close svg {
  width: 100%;
  height: 100%;
  fill: none;
}

.btn-close svg path {
  stroke: #198ACB;
  stroke-width: 1.5px;
  stroke-linecap: square;
  stroke-linejoin: round;
}

.btn-close:hover svg path {
  stroke: #146492;
}

.y-maps {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  overflow: hidden;
}

.catalog {
  position: relative;
}

.catalog.shown-map nav[aria-label="breadcrumb"] {
  display: none;
}

.catalog__title {
  color: #198acb;
}

.catalog.shown-map .catalog__title {
  margin-top: 72px;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .catalog.shown-map .catalog__title {
    margin-top: 22px;
    margin-bottom: 10px;
  }
}

.catalog__ymaps-container {
  /* position: fixed; */
  /* left: 0;
  bottom: 0; */
  width: 100%;
  height: calc(100vh - 205px);
  /* z-index: 1700; */
}

@media (max-width: 1659px) {
  .catalog__ymaps-container {
    height: calc(100vh - 175px);
  }
}

@media (max-width: 1399px) {
  .catalog__ymaps-container {
    height: calc(100vh - 170px);
  }
}

@media (max-width: 1199px) {
  .catalog__ymaps-container {
    height: calc(100vh - 155px);
  }
}

@media (max-width: 991px) {
  .catalog__ymaps-container {
    height: calc(100vh - 135px);
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .catalog__ymaps-container {
    height: calc(100vh - 260px);
  }
}

@media (max-width: 575px) {
  .catalog__ymaps-container {
    height: calc(100vh - 210px);
  }
}

.catalog__ymaps {
  width: 100%;
  height: 100%;
  /* padding-left: 449px; */
}

/* @media (max-width: 991px) {
  .catalog__ymaps {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .catalog__ymaps {
    padding-bottom: 50px;
  }
} */

.infinite-scroll-component {
  overflow: initial !important;
}

.city-container {
  position: relative;
}

.city-popup {
  display: none;
  padding: 20px;
  width: 340px;
  background-color: #fff;
  position: fixed;
  z-index: 100;
  right: 10px;
  top: 72px;
  font-size: 14px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.04), 0 20px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04),
    0 20px 40px rgba(0, 0, 0, 0.08);
  transition: display 2s linear;
}

@media screen and (min-width: 768px) {
  .city-popup {
    top: 90px;
    right: 30px;
  }
}

.city-popup.show {
  display: block;
}

.city-popup__header {
  margin-bottom: 30px;
}

.city-popup__title {
  font-size: 18px;
}

.city-popup__footer {
  display: flex;
  justify-content: space-between;
}

.catalog__search {
  position: relative;
}

.catalog__search input {
  width: 100%;
}

.advertise__address {
  position: relative;
}

.react-dadata__suggestions {
  position: absolute;
  background-color: white;
  width: 100%;
  top: 44px;
  left: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid rgb(224, 224, 224);
  border-top: none;
  z-index: 1800;
}

.custom-select {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.custom-select__toggle {
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.custom-select__toggle div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.custom-select__toggle div span:not(:empty):before {
  content: ":";
}

.custom-select__toggle svg {
  width: 1.4rem;
  height: 0.75rem;
  fill: none;
}

@media (max-width: 767px) {
  .custom-select__toggle svg {
    width: 1.2em;
  }
}

@media (max-width: 575px) {
  .custom-select__toggle svg {
    width: 1em;
  }
}

.custom-select__toggle svg line {
  stroke: #4f4f4f;
  stroke-width: 2px;
}

@media (max-width: 575px) {
  .custom-select__toggle svg line {
    stroke-width: 3px;
  }
}

.custom-select__toggle.btn svg line {
  stroke: #fff;
}

.custom-select_prepayment {
  max-width: 13em;
}

.custom-select_create-response-ad {
  border: 1px solid #bdbdbd;
  padding: 0.5em;
  border-radius: 5px;
}

.custom-select_create-response-ad button div {
  text-transform: capitalize;
}

.custom-select .dropdown-toggle::after {
  content: unset;
}

.custom-select .dropdown-list {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 100%;
  max-width: 500px;
  max-height: 300px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1800;
  padding: 0.5rem 0;
}

.custom-select .dropdown-list[data-alignment="right"] {
  left: unset;
  right: 0;
}

.custom-select .dropdown-list label.radio-line {
  display: block;
  position: relative;
  z-index: 1;
  text-align: left;
  justify-content: flex-start;
  color: #146492;
  padding: 0.5em 2em;
  cursor: pointer;
  white-space: nowrap;
}

.custom-select .dropdown-list label.radio-line:hover input {
  background-color: #ececec;
}

.custom-select .dropdown-list label.radio-line input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: pointer;
  background: transparent;
  border-radius: 0px;
  transition: 0.2s;
}

.custom-select .dropdown-list label.radio-line input:checked {
  background: #ececec;
}

.custom-select .dropdown-list__inner {
  max-height: 300px;
  overflow-y: scroll;
}

.custom-select .dropdown-list form {
  width: 100%;
}

.custom-select .dropdown-list__search {
  width: 100%;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgb(224, 224, 224);
}

/*.custom-select_city .custom-select__toggle_city div {*/
/*  color: #c59c7e;*/
/*}*/
.custom-select_city .dropdown-list {
  width: 260px;
  padding: 0;
}

@media (max-width: 991px) {
  .custom-select_city .dropdown-list[data-alignment="right"] {
    right: unset;
    left: 0;
  }
}

.custom-select_orderby {
  z-index: 1699;
}

.custom-select_orderby .dropdown-list {
  z-index: 1699;
}

.custom-select_district .dropdown-list__inner {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-select_district .dropdown-list__inner .checkbox-line {
  gap: 10px;
}

.custom-select.show .dropdown-list {
  display: block;
}

.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 3rem;
}

.not-found__title {
  text-align: center;
  margin: 0;
}

.not-found__link {
  text-align: center;
  padding: 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.service-card {
  padding: 2em;
  background: #ffffff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));
  margin-bottom: 2em;
  border-radius: 10px;
}

@media (max-width: 1659px) {
  .service-card {
    grid-gap: 1.25em 2em;
  }
}

@media (max-width: 1399px) {
  .service-card {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
}

@media (max-width: 1199px) {
  .service-card {
    padding: 1em;
  }
}

.service-card.page {
  display: block;
  padding: 2em 3em;
}

.service-card.page .title {
  flex-direction: row;
}

@media (max-width: 767px) {
  .service-card.page {
    padding: 2em;
  }
}

@media (max-width: 575px) {
  .service-card.page {
    padding: 1em;
  }
}

.service-card .photo {
  grid-column: 1/2;
  grid-row: 1/3;
}

.service-card .photo img {
  width: 160px;
  height: 160px;
  display: block;
}

@media (max-width: 1199px) {
  .service-card .photo img {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 767px) {
  .service-card .photo img {
    width: 120px;
    height: 120px;
  }
}

.service-card .btns {
  grid-column: 1/2;
  grid-row: 3/4;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.service-card .title {
  grid-column: 2/5;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1399px) {
  .service-card .title {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1199px) {
  .service-card .title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .service-card .title {
    font-size: 13px;
  }
}

@media (max-width: 575px) {
  .service-card .title {
    font-size: 12px;
  }
}

.service-card .desc {
  grid-column: 2/5;
  grid-row: 2/3;
}

.service-card .desc .text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.25em;
  overflow: hidden;
  font-weight: 500;
  background-color: #d0e0e9;
  color: #165d85;
}

.service-card .serv-list {
  grid-column: 2/5;
  grid-row: 3/4;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.service-card .serv-list .serv {
  background-color: #d0e0e9;
  color: #165d85;
  padding: 0.5em 1em;
  border-radius: 2em;
  font-weight: 500;
  margin-right: 0.75em;
  margin-bottom: 0.25em;
}

.service-card .delete {
  width: max-content;
}

.submit-alert {
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  border: none;
  z-index: 2100;
  border-radius: 0;
  text-align: center;
}

@media (max-width: 767px) {
  .submit-alert {
    bottom: 50px;
  }
}

.submit-alert span {
  font-weight: bold;
}

.auth-error {
  display: flex;
  padding: 5em 0;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.show-more {
  overflow: auto;
  height: 40em;
}

.send-msg {
  align-self: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.send-msg>svg {
  width: 24px;
  height: 24px;
}

.send-msg>svg>path {
  fill: #146492;
}

.send-msg:disabled svg>path {
  fill: #828282;
}

.mobile-item .dropdown-item {
  display: flex;
  gap: 10px;
}

.mobile-item .dropdown-item span {
  color: black;
}

.button-header-question {
  color: #c59c7e;
  font-size: 1.1em;
}

.button-header-question:hover {
  color: #146492;
}

.button-header-question__offcanvas {
  display: contents;
  color: #c59c7e;
  font-size: 1.3em;
}

.mobile-item__dropdown {
  padding: 0;
  display: flex;
  list-style: none;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid #828282;
  border-radius: 4px;
  max-width: 200px;
  z-index: 100;
}

.mobile-item__dropdown li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  white-space: nowrap;
}

.mobile-item__dropdown li:hover {
  background-color: #bdbdbd;
  color: #fff;
}

@media all and (min-width: 991px) {
  .mobile-item__dropdown {
    display: none;
  }
}

.counter {
  position: relative;
}

.counter span {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 18px;
  height: 18px;
  background-color: #146492;
  color: #fff;
  font-family: Lato;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 100;
}

.button-responses {
  color: #545454;
}

.button-responses:hover {
  color: #146492;
  /* color: #c59c7e; */
}

.card-page__ymaps {
  width: 100%;
  height: 350px;
}

.main-page__ymaps-container .map-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.75rem;
}

.main-page__ymaps-container .map-header div {
  margin-bottom: 0;
}


.main-page__ymaps-container .relative-divider {
  position: relative;
}

.main-page__ymaps-container .filters {
  position: absolute;
  right: 20px;
  top: 10px;
  background: white;
  max-height: 430px;
  width: auto;
  overflow-y: scroll;
  padding: 0.5rem;

}

.filters-ymap {
  font-size: 1rem;
}

.modal__map-filters input:not([type=checkbox]):not([type=radio]),
.main-page__ymaps-container input:not([type=checkbox]):not([type=radio]) {
  width: 100%;
}

.modal__map-filters {
  overflow-y: hidden;
}

.modal__map-filters .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal__map-filters .modal-content {
  margin: auto;
}

.modal__map-filters .modal-body {
  padding: 0.5em;
  max-height: 600px;
  overflow: scroll;
}


.main-page__ymaps {
  width: 100%;
  height: 450px;
}

.main-page__offcanvas-cards {
  height: 100%;
}

.offcanvas-filters-ymap {
  width: 100%;
  height: auto;
  max-height: 600px;
}

.response-card-ad {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  padding: 1em;
  background: #ffffff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));
}

.response-card-ad .photo img {
  width: 160px;
  height: 160px;
  margin: 0 auto;
  display: block;
}

@media (max-width: 1199px) {
  .response-card-ad .photo img {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 767px) {
  .response-card-ad .photo img {
    width: 120px;
    height: 120px;
  }
}

.response-card-ad .desc .text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slider-in-responses {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-in-responses .swiper-responses {
  display: flex;
  width: inherit;
  height: 350px;
}

.commercial {
  word-break: break-word;
}


#policy {
  width: 50%;
}

ol.policy {
  counter-reset: item;
}

ol.policy li.policy {
  display: block;
}

ol.policy li.policy:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}

ul.policy {
  list-style-type: "- " !important;
}

a.highlighted {
  color: #146492;
}

.single-link {
  color: inherit;
  transition: color 0.3s ease;
}

.single-link:hover {
  color: #146492;
}

.success-img {
  width: 100px;
  margin: 2rem;
}

.cookies__wrapper {
  opacity: 0;
  position: fixed;
  bottom: 0;
  left: auto;
  right: auto;
  z-index: 2000;
  width: 100vw;
  max-width: 100vw;
  padding: 1.25rem 1.5rem;
  background: white;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 1px 20px 0px #C59C7E;
  text-align: left;
  font-size: 0.8rem;

  @media screen and (min-width: 768px) {
    padding: 1rem 0.75rem;
    width: 340px;
    border-radius: 1rem;
    max-width: 340px;
    bottom: 1.75rem;
    right: 8rem;
  }

  transition: opacity 0.3s;
}

.cookies__wrapper.show {
  opacity: 1;
}

.cookies__title {
  line-height: 1.6;
  font-weight: 600;

  @media screen and (min-width: 768px) {
    line-height: initial;
    margin-bottom: 1rem;
  }
}

.cookies__content {
  margin-bottom: 1.85rem;
  font-weight: 600;
  line-height: 1.6;

  @media screen and (min-width: 768px) {
    line-height: initial;
    margin-bottom: 1.1rem;
    max-width: 303px;
    font-weight: 500;
  }
}

.cookies__control {
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }
}

.cookies__control button {
  @media screen and (min-width: 768px) {
    margin-right: 1rem;
  }
}





/* AI */



/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 *
*****************************************************************/
.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #198ACB;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #cccccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.c100.center {
  float: none;
  margin: 0 auto;
}

.c100.big {
  font-size: 200px;
}

.c100.small {
  font-size: 80px;
}

.c100>span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  background-color: whitesmoke;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}

.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}

.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}

.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}

.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}

.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}

.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}

.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}

.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}

.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}

.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}

.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}

.c100.p13 .bar {
  -webkit-transform: rotate(46.8deg);
  -moz-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  -o-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}

.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}

.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}

.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}

.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}

.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}

.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}

.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}

.c100.p21 .bar {
  -webkit-transform: rotate(75.6deg);
  -moz-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  -o-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}

.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}

.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}

.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}

.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.c100.p26 .bar {
  -webkit-transform: rotate(93.6deg);
  -moz-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  -o-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}

.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}

.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}

.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}

.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}

.c100.p31 .bar {
  -webkit-transform: rotate(111.6deg);
  -moz-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  -o-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}

.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}

.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}

.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}

.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}

.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}

.c100.p37 .bar {
  -webkit-transform: rotate(133.2deg);
  -moz-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  -o-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}

.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}

.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}

.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}

.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}

.c100.p42 .bar {
  -webkit-transform: rotate(151.2deg);
  -moz-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  -o-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}

.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}

.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}

.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}

.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}

.c100.p47 .bar {
  -webkit-transform: rotate(169.2deg);
  -moz-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  -o-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}

.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}

.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}

.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}

.c100.p52 .bar {
  -webkit-transform: rotate(187.2deg);
  -moz-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  -o-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}

.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}

.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}

.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}

.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}

.c100.p57 .bar {
  -webkit-transform: rotate(205.2deg);
  -moz-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  -o-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}

.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}

.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}

.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}

.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}

.c100.p62 .bar {
  -webkit-transform: rotate(223.2deg);
  -moz-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  -o-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}

.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}

.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}

.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}

.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}

.c100.p67 .bar {
  -webkit-transform: rotate(241.2deg);
  -moz-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  -o-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}

.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}

.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}

.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}

.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}

.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}

.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}

.c100.p74 .bar {
  -webkit-transform: rotate(266.4deg);
  -moz-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  -o-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}

.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}

.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}

.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}

.c100.p79 .bar {
  -webkit-transform: rotate(284.4deg);
  -moz-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  -o-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}

.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}

.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}

.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}

.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}

.c100.p84 .bar {
  -webkit-transform: rotate(302.4deg);
  -moz-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  -o-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}

.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}

.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}

.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}

.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}

.c100.p89 .bar {
  -webkit-transform: rotate(320.4deg);
  -moz-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  -o-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}

.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}

.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}

.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}

.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}

.c100.p94 .bar {
  -webkit-transform: rotate(338.4deg);
  -moz-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  -o-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}

.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}

.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}

.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}

.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}

.c100.p99 .bar {
  -webkit-transform: rotate(356.4deg);
  -moz-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  -o-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}

.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.c100:hover {
  cursor: default;
}

.c100>span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;

  color: #198ACB;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  color: #198ACB;


}

.c100:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}

.c100.dark {
  background-color: #777777;
}

.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}

.c100.dark>span {
  color: #777777;
}

.c100.dark:after {
  background-color: #666666;
}

.c100.dark:hover>span {
  color: #c6ff00;
}

.c100.green .bar,
.c100.green .fill {
  border-color: #4db53c !important;
}

.c100.green:hover>span {
  color: #4db53c;
}

.c100.green.dark .bar,
.c100.green.dark .fill {
  border-color: #5fd400 !important;
}

.c100.green.dark:hover>span {
  color: #5fd400;
}

.c100.orange .bar,
.c100.orange .fill {
  border-color: #dd9d22 !important;
}

.c100.orange:hover>span {
  color: #dd9d22;
}

.c100.orange.dark .bar,
.c100.orange.dark .fill {
  border-color: #e08833 !important;
}

.c100.orange.dark:hover>span {
  color: #e08833;
}


@media (min-width: 576px) {

  .filter-modal .modal-content,
  .filter-modal .modal-dialog {
    width: calc(100vw * .8) !important;
    max-width: calc(100vw * .8) !important;
    height: auto;
  }
}

input[type="date"] {
  padding-left: 50px !important;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  /* display: none; */
  left: 20px;
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='22' viewBox='0 0 19 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.44444 13.1V11H10.5556V13.1H8.44444ZM4.22222 13.1V11H6.33333V13.1H4.22222ZM12.6667 13.1V11H14.7778V13.1H12.6667ZM8.44444 17.3V15.2H10.5556V17.3H8.44444ZM4.22222 17.3V15.2H6.33333V17.3H4.22222ZM12.6667 17.3V15.2H14.7778V17.3H12.6667ZM0 21.5V2.6H3.16667V0.5H5.27778V2.6H13.7222V0.5H15.8333V2.6H19V21.5H0ZM2.11111 19.4H16.8889V8.9H2.11111V19.4Z' fill='%23198ACB'/%3E%3C/svg%3E%0A");
  /* background: none; */
  /* background: url(./bg-imgs/calendar.svg) no-repeat; */
  width: 19px;
  height: 19px;
}


.tooltip-arrow::before {
  border-top-color: #FFF !important;
}

.tooltip-inner {
  background: #FFF;
  color: #000;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 22%);
}

.tooltip {
  z-index: 10000;
  border-radius: 15px;
}
