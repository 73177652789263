.welcomeContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation: outblack 0.25s ease-in-out forwards;
  animation-delay: 1.25s;
}

.welcomeContainerHideBlock{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: white;
  animation: outblack 1s ease-in-out forwards;
}

@keyframes outblack {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.welcomeText {
  position: relative;
  font-weight: 600;
  font-size: 3em;
  text-align: center;
  border-radius: 30px;
  /* border: 5px solid #FFF; */
  padding: 50px 100px;
  box-shadow: inset 0 0 0 300px #24292D99;
  /* background-color: #24292D; */
  /* opacity: .5; */
  background: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 500px;
  height: 200px; */
}

.welcomeText::before {
  border-radius: 30px;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100%);
  height: calc(100%);
  filter: blur(.1em);
  background: inherit;
  box-shadow: inset 0 0 0 300px #24292D99;
}

.welcomeText p {
  z-index: 10;
  /* position: absolute; */
}


@media (max-width: 600px) {
  .welcomeText {
    font-size: 2.5em;
    border-radius: 16px;
    padding: 20px 40px;
  }
}

@media (max-width: 360px) {
  .welcomeText {
    font-size: 2em;
    border-radius: 16px;
    padding: 20px 40px;
  }
}

/* Morning */
@media (max-width: 600px) {
  .welcomeContainer.morning {
    background-image: url(welcome-bgs/morning_xm_cartoon.jpg);
  }
}

@media (min-width: 601px) {
  .welcomeContainer.morning {
    background-image: url(welcome-bgs/morning_xl_cartoon_1.jpg);
  }
}

.welcomeContainer.morning .welcomeText {
  color: #F9C717;
}

/* Day */
@media (max-width: 600px) {
  .welcomeContainer.day {
    background-image: url(welcome-bgs/day_xm_cartoon.jpg);
  }
}

@media (min-width: 601px) {
  .welcomeContainer.day {
    background-image: url(welcome-bgs/day_xl_cartoon.jpg);
  }
}

.welcomeContainer.day .welcomeText {
  color: #FF994F;
}

/* Evening */
@media (max-width: 600px) {
  .welcomeContainer.evening {
    background-image: url(welcome-bgs/evening_xm_cartoon.jpg);
  }
}

@media (min-width: 601px) {
  .welcomeContainer.evening {
    background-image: url(welcome-bgs/evening_xl_cartoon.jpg);
  }
}

.welcomeContainer.evening .welcomeText {
  color: #F99E17;
}

/* Night */
@media (max-width: 600px) {
  .welcomeContainer.night {
    background-image: url(welcome-bgs/night_xm_cartoon.jpg);
  }
}

@media (min-width: 601px) {
  .welcomeContainer.night {
    background-image: url(welcome-bgs/night_xl_cartoon.jpg);
  }
}

.welcomeContainer.night .welcomeText {
  color: #17DEF9;
}

.welcomeContainer.morning .welcomeText .userNameText,
.welcomeContainer.evening .welcomeText .userNameText {
  color: #fff;
}
