@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

$main-font: "Roboto";
$title-font: "Gilroy";
$color-1: #146492;
$color-2: #c59c7e;
$color-3: #ececec;
$gray-1: #333333;
$gray-2: #4f4f4f;
$gray-3: #828282;
$gray-4: #bdbdbd;
$gray-5: #e0e0e0;
$gray-6: #f2f2f2;
$white: #fff;
$black: #000;
$green: #15a672;
$blue: #2d9cdb;
$red: #E41313;

$modal-z-index: 2000;
$offcanvas-z-index: 2000;
$header-z-index: 1900;
$select-z-index: 1800;
$ymaps-z-index: 1700;

// ICONS

.check-icon {
  fill: $color-1;
}

.copy-icon,
.edit-icon,
.trash-icon,
.choose-icon {
  fill: $black;
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

p,
label,
span,
a,
form,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl {
  line-height: normal;
  margin-bottom: 0px;
}

html,
body,
#root {
  height: 100%;
}

// прижать футер к низу страницы
.root-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;

  header {
    flex: 0 0 auto;
    position: sticky;
  }

  &__content {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  footer {
    flex: 0 0 auto;
  }
}

/*****************
SCROLL STYLIZATION
******************/
html {
  scrollbar-color: #e0e0e0 transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;

  &:hover,
  &:active {
    background: #f2f2f2;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 10px;
  transition: 0.2s;

  &:hover {
    background-color: #bdbdbd;
  }

  &:active {
    background-color: $color-2;
  }
}

::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

/* mixins */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* FORMS CUSTOMIZATION */
input,
textarea,
.inp {
  background: #ffffff;
  border: 1px solid $gray-5;
  border-radius: 0.3em;
  padding: 0.5em 0.85em;
  width: 100%;
  line-height: normal;
  box-shadow: unset;
  outline: none;

  &:not(:placeholder-shown) {
    border: 1px solid $gray-4;
  }

  &:hover {
    border: 1px solid $gray-3;
  }

  &:focus {
    border: 1px solid $color-1;
  }
}

input {
  &[type="number"] {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: calc(100% - 0.5em) center;
    padding: 0.5em 0.5em 0.5em 0.5em;
    width: 100px;
    max-width: 100%;
    -moz-appearance: textfield; //Firefox
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none; //Chrome, Safari, Edge, Opera
      margin: 0;
    }

    &.price {
      background-image: url(bg-imgs/rub.svg);
      padding: 0.5em 1.5em 0.5em 0.5em !important;
    }

    &.area {
      background-image: url(bg-imgs/м2.svg);
      padding: 0.5em 1.5em 0.5em 0.5em;
    }

    &.length {
      background-image: url(bg-imgs/м.svg);
      padding: 0.5em 1.5em 0.5em 0.5em;
    }

    &.percent {
      background-image: url(bg-imgs/percent.svg);
      padding: 0.5em 1.5em 0.5em 0.5em;
    }
  }

  &[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border: none;
    width: 1.3em;
    height: 1.3em;
    background-color: transparent;
    background: url(bg-imgs/radio.svg) no-repeat center;
    background-size: contain;
    padding: 0px;

    &:checked {
      background: url(bg-imgs/radio-ch.svg) no-repeat center;
      background-size: contain;
    }
  }

  &[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    width: 16px;
    height: 16px;
    background-color: transparent;
    padding: 0px;
    border-radius: 2px;
    box-sizing: content-box;

    &:checked {
      // background-color: $color-1;
      background: url(bg-imgs/mark2.svg) no-repeat center;
      background-size: auto;
    }
  }
}

label {
  display: flex;
  align-items: center;

  input {
    &:checked + span {
      color: $color-1;
    }
  }

  span {
    flex: 1;
    max-width: calc(100% - 1.3em - 0.5rem);
  }

  &.inp-text {
    div {
      padding: 0.5em 1em;
      background-color: $color-2;
      color: #fff;
      border-radius: 2em;
    }

    input {
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

      &:checked + div {
        background-color: $color-1;
      }
    }
  }
}

.inp-btn {
  position: relative;

  div {
    position: relative;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0.2em 0.6em;
    line-height: normal;
    z-index: 2;
  }

  input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: none;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:checked + div {
      background-color: $color-1;
      color: #fff;
    }
  }
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: fit-content;
  outline: none;
  border: none;
  line-height: normal;
  background: url(bg-imgs/caret-down.svg) no-repeat right center;
  background-size: 1em 0.5em;
  padding: 0 1.2em 0 0;
}

.input-file {
  @include flex-center();
  position: relative;
  height: 2.5em;
  width: 2.5em;
  padding: 0.3em;
  border-radius: 0.25em;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: $color-2;

    img {
      filter: brightness(0) invert(1);
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 1px;
    height: 1px;
    top: 0px;
    left: 0px;
    padding: 0px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    transition: 0.2s;
  }

  .ind {
    position: absolute;
    bottom: -0.5em;
    right: -0.25em;
    width: 1.2em;
    height: 1.2em;
    font-size: 0.9em;
    border-radius: 50%;
    @include flex-center();
    background-color: $color-3;
    color: $color-1;
    font-weight: 600;

    &:empty {
      display: none;
    }
  }
}

.upload__image-wrapper {
  border: 1px dashed $gray-5;
  padding: 1em;
  border-radius: 0.25em;
  min-width: fit-content;
  max-width: 100%;

  .imgs-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.75em;
    width: 100%;

    &:empty {
      min-width: 140px;
      min-height: 100px;
      background: url(bg-imgs/photo.svg) no-repeat;
      background-size: contain;
      background-position: center center;
    }

    .image-item {
      position: relative;
      width: calc((100% - 6%) / 6);
      margin: 0 1% 1% 0;
      @media (max-width: 1399px) {
        width: calc((100% - 5%) / 5);
      }
      @media (max-width: 1199px) {
        width: calc((100% - 4%) / 4);
      }
      @media (max-width: 575px) {
        width: calc((100% - 3%) / 3);
      }

      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100px;
        object-fit: cover;
        @media (max-width: 1659px) {
          height: 90px;
        }
        @media (max-width: 575px) {
          height: 23vw;
        }
      }

      &__btn-wrapper {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.44);
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        opacity: 0;
        transition: 0.2s ease;
        color: #fff;

        &:hover,
        &:focus {
          opacity: 1;
        }

        button {
          width: 2em;
          height: 2em;

          &.main-img {
            width: 100%;
            font-size: 0.8em;
          }

          img {
            width: 1em;
            height: 1em;
            object-fit: contain;
          }
        }
      }

      &__btn-wrapper-in-response {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.44);
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        transition: 0.2s ease;
        color: #fff;
        opacity: 1;

        button {
          height: 2em;

          &.main-img {
            width: 100%;
            font-size: 0.8em;
          }

          img {
            width: 1em;
            height: 1em;
            object-fit: contain;
          }
        }
      }

      .mark {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0.44);
        color: #fff;
        font-size: 0.8em;
        text-align: center;
        z-index: 3;
      }
    }
  }
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}

.btn {
  @include flex-center;
  border-radius: 4px;
  padding: 0.5em 2em;
  text-align: center;
  transition: 0.5s;
  width: fit-content;
  font-size: 1em;
  line-height: normal;

  &-1 {
    background-color: $color-1 !important;
    color: #fff !important;

    &:hover,
    &:focus,
    &:active {
      color: #fff !important;
      background-color: darken($color-1, 6%) !important;
    }

    &:disabled {
      color: #c4c4c4 !important;
      background-color: #7a7878 !important;
    }
  }

  &-2 {
    background-color: $color-2 !important;
    color: #fff !important;

    &:hover,
    &:focus,
    &:active {
      color: #fff !important;
      background-color: darken($color-2, 6%) !important;
    }

    &:disabled {
      color: #c4c4c4 !important;
      background-color: #7a7878 !important;
    }

    svg {
      line {
        stroke: #fff;
      }
    }
  }

  &-3 {
    background-color: $gray-5 !important;
    color: $gray-3 !important;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active {
      color: #fff !important;
      background-color: $color-1 !important;
    }

    &:disabled {
      color: #c4c4c4 !important;
      background-color: #7a7878 !important;
    }
  }

  &-rad2 {
    border-radius: 2em;
  }

  &-fav {
    width: 2.125em;
    height: 2.125em;
    border-radius: 50%;
    background-color: $color-3;
    @include flex-center;
    padding-top: 2px;

    svg {
      width: 1.375em;
      height: 1.375em;
      fill: none;

      path {
        fill: $gray-3;
        transition: 0.2s ease-in-out;
      }
    }

    &:hover,
    &:focus {
      svg {
        path {
          fill: $color-1;
        }
      }
    }

    &:active,
    &.clicked {
      background-color: $color-2;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &-pin {
    width: 2.125em;
    height: 2.125em;
    border-radius: 50%;
    background-color: $color-3;
    @include flex-center;

    svg {
      width: 1em;
      height: 1.125em;
      fill: none;

      path {
        fill: $color-1;
      }
    }
  }

  &-notice {
    width: 2.125em;
    height: 2.125em;
    border-radius: 50%;
    background-color: $color-3;
    @include flex-center;
    padding-bottom: 2px;

    svg {
      width: 1.3em;
      height: 1.3em;
      fill: none;

      path.fill-color {
        fill: $gray-3;
        transition: 0.2s ease-in-out;
      }
    }

    &:hover,
    &:focus {
      svg {
        path.fill-color {
          fill: $color-1;
        }
      }
    }

    &.reported > svg > path.fill-color {
      fill: $color-1;
    }
  }
}

h1,
h2,
h3 {
  font-family: $title-font;
}

h1 {
  text-align: left;
  font-weight: 700;
  margin-bottom: 1em;
  font-size: 1.875em;
  color: #16445e;

  &.main {
    text-align: center;
    font-size: 3em;
    color: $color-1;
    width: fit-content;
    background-color: #fff;
    padding: 0.1em 1em;
    @media (max-width: 1199px) {
      font-size: 2.5em;
      padding: 0.1em 0.5em;
    }
    @media (max-width: 1199px) {
      font-size: 2em;
    }
  }
}

h2 {
  text-align: center;
  font-size: 2.25em;
  font-weight: 600;
  margin-bottom: 1em;
  color: $color-1;
  @media (max-width: 1199px) {
    font-size: 2em;
  }
}

h3 {
  text-align: left;
  font-size: 1.75em;
  font-weight: 700;
  margin-bottom: 1em;
  color: $color-1;
  @media (max-width: 1199px) {
    font-size: 1.5em;
  }
}

h4 {
  font-family: $title-font;
  text-align: left;
  color: #545454;
  font-size: 1.5em;
  font-weight: 700;
}

h5 {
  font-family: $title-font;
  text-align: left;
  font-size: 1.25em;
  font-weight: 500;
}

.title-font {
  font-family: $title-font;
}

.fs {
  &-07 {
    font-size: 0.7em;
  }

  &-08 {
    font-size: 0.8em;
  }

  &-09 {
    font-size: 0.9em;
  }

  &-11 {
    font-size: 1.1em;
  }

  &-12 {
    font-size: 1.2em;
  }

  &-13 {
    font-size: 1.3em;
  }

  &-14 {
    font-size: 1.4em;
  }

  &-15 {
    font-size: 1.5em;
  }

  &-16 {
    font-size: 1.6em;
  }

  &-17 {
    font-size: 1.7em;
  }

  &-18 {
    font-size: 1.8em;
  }

  &-19 {
    font-size: 1.9em;
  }

  &-20 {
    font-size: 2em;
  }

  &-90 {
    font-size: 9em;
  }
}

.fw {
  &-3 {
    font-weight: 300;
  }

  &-4 {
    font-weight: 400;
  }

  &-5 {
    font-weight: 500;
  }

  &-6 {
    font-weight: 600;
  }

  &-7 {
    font-weight: 700;
  }

  &-8 {
    font-weight: 800;
  }

  &-9 {
    font-weight: 900;
  }
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.gray-1 {
  color: $gray-1;
}

.gray-2 {
  color: $gray-2;
}

.gray-3 {
  color: $gray-3;
}

.gray-4 {
  color: $gray-4;
}

.color-1 {
  color: $color-1;
}

.color-2 {
  color: $color-2;
}

.color-3 {
  color: $color-3;
}

.blue {
  color: $blue;
}

.word-b {
  word-break: break-all;
}

.bb-1 {
  border-bottom: 1px solid;
}

.shad-box {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  &-2 {
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
    border-radius: 2em;
  }
}

.w-fit {
  width: fit-content;
}

.flex-1 {
  flex: 1;
}

.container {
  @media (min-width: 1660px) {
    width: 1596px;
    max-width: 1596px;
  }
}

.mb-6 {
  margin-bottom: 6em;
  @media (max-width: 575px) {
    margin-bottom: 4em;
  }
}

.position-lg-relative {
  @media (min-width: 992px) {
    position: relative !important;
  }
}

.show-phone {
  position: relative;
  width: 100%;
  background-color: #fff;
  @include flex-center();

  .phone {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    @include flex-center();
  }

  button {
    position: relative;
    z-index: 1;
    width: 100%;
    transition: 0.2s;

    &.invisible {
      z-index: -1;
      opacity: 0;
    }
  }
}

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0px;
  border: none !important;

  li {
    a.dropdown-item {
      color: $color-1 !important;
      padding: 0.5em 2em !important;
      transition: 0.2s;

      &:hover,
      &:focus,
      &.active,
      &:active {
        background-color: $color-3;
      }
    }
  }
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #545454;
  font-size: 16px;
  background-color: #fff;
  @media (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
  }
}

header {
  background: $white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 1em 0;
  position: sticky;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 70px;
  z-index: $header-z-index;
  @media (max-width: 1199px) {
    padding: 0.5em 0;
    height: 55px;
  }
  @media (max-width: 991px) {
    height: 50px;
  }
  @media (max-width: 575px) {
    height: 48px;
    padding: 0.75em 0;
  }

  .container {
    display: flex;
    align-items: center;
    position: relative;

    nav {
      display: flex;
      align-items: center;

      a {
        color: $color-2;
        padding: 0.5em 1em;
        font-size: 1.1em;
        font-weight: 400;
        transition: 0.2s;

        &.active,
        &:hover,
        &:focus {
          color: $color-1;
        }

        @media (max-width: 1199px) {
          padding: 0.5em;
        }
      }
    }

    @media (max-width: 991px) {
      justify-content: space-between;
    }
  }

  .logo {
    max-height: 38px;
    object-fit: scale-down;
    object-position: center;
  }

  .custom-select {
    @media (max-width: 575px) {
      max-width: 80px;
    }

    > button {
      font-size: 0.9em;
      @media (max-width: 991px) {
        font-size: 1.1em;
      }

      svg {
        width: 1em;
        height: 1em;

        line {
          stroke: $color-2;
        }
      }
    }
  }

  .btn {
    @media (max-width: 575px) {
      font-size: 0.9em;
    }
  }
}

main {
  min-height: calc(100vh - 470px);
  position: relative;
  @media (max-width: 1199px) {
    min-height: calc(100vh - 455px);
  }
  @media (max-width: 991px) {
    min-height: calc(100vh - 415px);
  }
  @media (max-width: 767px) {
    min-height: calc(100vh - 100px);
  }
  @media (max-width: 575px) {
    min-height: calc(100vh - 98px);
  }
}

#sec-1 {
  height: 650px;
  @include flex-center;
  // background: url(bg-imgs/bg1.png) no-repeat;
  // background-size: cover;
  position: relative;
  z-index: 1;
  padding-bottom: 5em;
  margin-bottom: -5em;

  &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 5em;
    background: rgba(36, 44, 49, 0.22);
    filter: blur(36px);
  }

  @media (max-width: 991px) {
    height: 550px;
  }
  @media (max-width: 767px) {
    height: 500px;
  }
  @media (max-width: 575px) {
    height: 400px;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

/* SECTION 2 */
.tiles {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .tile {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: calc((100% - 150px) / 5);
    margin-bottom: 25px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    img {
      height: 135px;
      width: 135px;
      object-fit: scale-down;
      display: block;
      margin: 0 auto;
    }

    .links {
      width: 100%;
      border-radius: 4px;
      background: #ffffff;
      position: relative;
      height: 100px;
      @include flex-center;
      flex-wrap: wrap;
      padding: 1em 0;

      .title {
        font-size: 1.5em;
        font-weight: 500;
        color: $color-2;
        margin: 0 0.5em;
        transition: 0.3s;
        text-align: center;
      }

      .services-links {
        &:hover {
          color: $color-1;
        }
      }

      .hover-links {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        z-index: 2;
        opacity: 0;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 1em 0;
        @include flex-center;
        flex-wrap: wrap;
        transition: 0.2s;
        @media (max-width: 1399px) {
          padding: 0.5em 0;
        }

        div {
          margin: 0 0.5em;

          &::before {
            content: "⦁";
            color: $color-1;
            font-size: 1.5em;
          }

          a {
            font-size: 1.5em;
            font-weight: 500;
            color: $color-1;
            text-align: center;
            @media (max-width: 1659px) {
              font-size: 1.25em;
            }

            &:hover,
            &:focus {
              border-bottom: 1px solid;
            }
          }
        }
      }

      &:hover {
        .hover-links {
          opacity: 1;
        }
      }
    }

    .click-links {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: #fff;
      @include flex-center();
      flex-direction: column;

      .titles {
        @include flex-center();
        flex-wrap: wrap;
        width: 100%;
        text-align: center;
        color: $color-2;
        font-weight: 500;
        font-size: 1.4em;
        @media (max-width: 575px) {
          font-size: 1.2em;
        }

        .title {
          margin: 0 0.5em;
        }
      }

      ul {
        padding-left: 1.5em;

        li {
          color: $color-1;
          font-weight: 500;
          font-size: 1.3em;
          margin-bottom: 0.4em;
          @media (max-width: 575px) {
            margin-bottom: 0.5em;
            font-size: 1.1em;
          }
        }
      }
    }
  }

  @media (max-width: 1659px) {
    .tile {
      height: 250px;

      .links {
        height: 90px;

        .title {
          font-size: 1.25em;
        }
      }
    }
  }
  @media (max-width: 1399px) {
    justify-content: space-between;
    .tile {
      height: 240px;
      width: calc((100% - 80px) / 5);
      margin-bottom: 20px;

      .links {
        height: 80px;
        padding: 0.5em 0;
      }
    }
  }
  @media (max-width: 1199px) {
    justify-content: center;
    .tile {
      width: calc((100% - 80px) / 4);
      margin-left: 10px;
      margin-right: 10px;

      img {
        height: 100px;
        width: 100px;
        margin: 0 auto 15px;
      }
    }
  }
  @media (max-width: 991px) {
    .tile {
      width: calc((100% - 60px) / 3);

      .hover-links {
        opacity: 1;
      }
    }
  }
  @media (max-width: 767px) {
    .tile {
      width: calc((100% - 40px) / 2);

      .links {
        height: 70px;
      }
    }
  }
  @media (max-width: 575px) {
    .tile {
      width: calc((100% - 20px) / 2);
      height: calc(30vw + 55px);
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 8px;

      img {
        height: 20vw;
        width: 20vw;
        margin: 0 auto 5px;
      }

      .links {
        height: 55px;
        padding: 0.6em 0;

        a {
          margin: 0 0.3em;
        }
      }
    }
  }
}

/* card */
.card-mini {
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  // .photo {
  //     width: 100%;
  //     height: 280px;
  //     object-fit: cover;
  // }
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  .address {
    img {
      width: 0.875em;
    }
    text-transform: capitalize;
  }

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 3; // количество строк
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2em;
    height: 3.6em;
    overflow: hidden;
    font-size: 0.9em;
    font-weight: 300;
  }

  .btn-fav {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
  }
}

.labels {
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 10;

  div {
    color: #fff;
    padding: 0.1em 0.25em;
    margin-bottom: 0.2em;
    font-weight: 500;
    font-size: 0.95em;
    display: flex;
    align-items: center;

    img {
      margin-right: 0.15em;
      width: 1.5em;
      height: 1.5em;
      object-fit: scale-down;
    }

    &.vip {
      background-color: $color-1;
    }

    &.hot {
      background-color: $color-2;
    }
  }
}

.card-midi {
  position: relative;
  background-color: #fff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
  padding: 1em;
  align-items: flex-start;

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 5; // количество строк
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2em;
    height: 6em;
    overflow: hidden;
    font-size: 0.9em;
    font-weight: 300;
  }

  .bottom-btns {
    display: flex;
    align-items: center;

    > * {
      margin-right: 1em;
    }
  }

  .img-preview {
    margin-top: 0.75em;
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .wrap {
      width: calc((100% - 1em) / 3);
      position: relative;

      &:not(:first-child) {
        margin-left: 0.5em;
      }

      img {
        width: 100%;
        height: 90px;
        object-fit: cover;
        @media (max-width: 1659px) {
          height: 80px;
        }
      }

      .extra {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.35);
        color: #fff;
        font-family: $title-font;
        font-size: 0.9em;
        font-weight: 600;
        text-align: center;
        @include flex-center;
        margin-left: 0em;
      }
    }
  }

  .author {
    img {
      width: 115px;
      height: 115px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .show-phone {
    position: relative;
    width: 160px;
    height: 35px;
    @include flex-center();

    div {
      position: relative;
      z-index: 1;
    }

    button {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}

.hover-slider {
  height: 280px;
  width: 100%;
  position: relative;
  @media (max-width: 1659px) {
    height: 240px;
  }
  @media (max-width: 1399px) {
    height: 270px;
  }
  @media (max-width: 1199px) {
    height: 240px;
  }
  @media (max-width: 991px) {
    height: 260px;
  }
  @media (max-width: 767px) {
    height: 200px;
  }
  @media (max-width: 575px) {
    height: 67vw;
  }

  .sld {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: 1s;
    opacity: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.active {
      opacity: 1;
    }

    .extra {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      color: #fff;
      font-family: $title-font;
      font-size: 1.2em;
      font-weight: 600;
      @include flex-center;
    }
  }

  .box {
    position: relative;
    display: flex;
    z-index: 1;
    width: 100%;
    height: 100%;

    .section {
      flex-grow: 1;
      margin: 0px;
      display: flex;
      align-items: flex-end;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 5px;
        left: 2px;
        right: 2px;
        height: 5px;
        background-color: $color-3;
        transition: 0.3s;
      }

      &.active::after {
        background-color: $color-1;
      }
    }
  }
}

/* SECTION 5 */
#sec-5 {
  background: url(bg-imgs/bg2.png) no-repeat center bottom;
  background-size: 100% auto;

  .info {
    @media (max-width: 575px) {
      font-size: 10px;
    }
  }
}

article {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

  img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    @media (max-width: 1659px) {
      height: 240px;
    }
    @media (max-width: 1399px) {
      height: 220px;
    }
    @media (max-width: 1199px) {
      height: 200px;
    }
    @media (max-width: 767px) {
      height: 160px;
    }
    @media (max-width: 575px) {
      height: 55vw;
    }
  }

  h2 {
    @media (max-width: 1659px) {
      font-size: 2em;
    }
    @media (max-width: 1399px) {
      font-size: 1.75em;
    }
    @media (max-width: 1199px) {
      font-size: 1.5em;
    }
  }

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2em;
    height: 12em;
    overflow: hidden;
    font-size: 0.9em;
    font-weight: 300;
    @media (max-width: 1399px) {
      -webkit-line-clamp: 8;
      line-height: 1.2em;
      height: 9.6em;
    }
    @media (max-width: 1199px) {
      -webkit-line-clamp: 6;
      line-height: 1.2em;
      height: 7.2em;
    }
  }
}

/* SECTION 6 - catalog */
.form-search {
  position: relative;
  z-index: $select-z-index;
  --title-size: 1.5em;
  --size: 1.1em;
  display: grid;
  grid-template-columns: 160px 215px 270px auto 180px;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1em 0.5em;
  width: 100%;
  @media (max-width: 1659px) {
    --title-size: 1.4em;
  }
  @media (max-width: 1399px) {
    --title-size: 1.3em;
    grid-template-columns: 130px 180px 250px auto 160px;
  }
  @media (max-width: 1199px) {
    --title-size: 1.2em;
    --size: 1em;
    grid-template-columns: 120px 150px 190px auto 140px;
  }
  @media (max-width: 991px) {
    grid-template-columns: 30% 30% 4fr;
    grid-template-rows: repeat(3, 1fr);
    z-index: $ymaps-z-index + 1;
  }
  @media (max-width: 767px) {
    grid-gap: 0.75em 0.5em;
  }
  @media (max-width: 575px) {
    --title-size: 1em;
    grid-gap: 0.5em 0.25em;
  }

  .map-search {
    grid-column: 1/6;
    grid-row: 1/2;
    font-size: var(--size);
    display: flex;
    justify-content: flex-end;
    @media (max-width: 991px) {
      grid-column: 1/4;
      grid-row: 3/4;
      justify-content: space-between;
      align-items: center;
      img {
        width: 1em;
      }
    }
  }

  .sel-1 {
    grid-column: 1/2;
    grid-row: 2/3;
    width: 100%;
    @media (max-width: 991px) {
      grid-row: 1/2;
    }

    .btn {
      font-size: var(--title-size);
    }

    label {
      font-size: var(--size);
    }
  }

  .sel-2 {
    grid-column: 2/3;
    grid-row: 2/3;
    width: 100%;
    @media (max-width: 991px) {
      grid-row: 1/2;
    }

    .btn {
      font-size: var(--title-size);
    }

    label {
      font-size: var(--size);
    }
  }

  .sel-3 {
    grid-column: 3/4;
    grid-row: 2/3;
    width: 100%;
    @media (max-width: 991px) {
      grid-row: 1/2;
    }

    .btn {
      width: 100%;
      font-size: var(--title-size);
    }

    label {
      font-size: var(--size);
    }
  }

  input[type="search"],
  .catalog__search input {
    position: relative;
    grid-column: 4/5;
    grid-row: 2/3;
    width: 100%;
    font-size: var(--title-size);
    @media (max-width: 991px) {
      grid-column: 1/3;
      grid-row: 2/3;
    }
  }

  button[type="submit"] {
    grid-column: 5/6;
    grid-row: 2/3;
    width: 100%;
    font-size: var(--title-size);
    @media (max-width: 991px) {
      grid-column: 3/4;
      grid-row: 2/3;
    }
  }

  .popular-queries {
    position: relative;
    scroll-direction: horizontal;
    grid-column: 1/6;
    grid-row: 3/4;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    font-size: 1.1em;
    -ms-overflow-style: none;

    @media (max-width: 991px) {
      display: none;
    }

    div {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 1;
      background-color: white;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding-right: 10px;
      box-shadow: 10px 0 5px 2px white;
    }

    button {
      color: $color-1;
      margin-left: 3em;
      font-weight: 500;
      border-bottom: 1px solid $color-1;
      white-space: nowrap;
    }

    // &::-webkit-scrollbar {
    //   height: 0;
    // }
  }
}

.btn-view {
  svg {
    rect {
      fill: $gray-5;
    }
  }

  &:hover {
    svg {
      rect {
        fill: $color-1;
      }
    }
  }
}

.btn-filter.btn-collapse {
  display: flex;
  align-items: center;

  span {
    &::after {
      content: "Расширенный фильтр";
    }
  }

  svg {
    transition: 0.2s;
  }

  &[aria-expanded="true"] {
    svg {
      transform: rotate(180deg);
    }

    span {
      &::after {
        content: "Свернуть фильтр";
      }
    }
  }
}

/* SECTION 7 - card page */
#sec-7 {
  .icon-mini {
    width: 1.25em;
    height: 1.25em;
    object-fit: scale-down;
  }

  .btns {
    display: flex;

    button {
      width: 2.2em;
      height: 2.2em;
      margin-right: 1em;
    }
  }

  .frame {
    @media (max-width: 1659px) {
      font-size: 14px;
    }
    @media (max-width: 1199px) {
      font-size: 12px;
    }
  }
}

#ReactSimpleImageViewer {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.75);

  .react-simple-image-viewer__next,
  .react-simple-image-viewer__previous,
  .react-simple-image-viewer__close {
    opacity: 0.4;
  }
}

.author {
  img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    @media (max-width: 1199px) {
      width: 100px;
      height: 100px;
    }
    @media (max-width: 575px) {
      width: 80px;
      height: 80px;
    }
  }
}

.frame {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.column-2 {
  column-count: 2;
  column-gap: 150px;
  padding-right: 150px;
  @media (max-width: 1659px) {
    column-gap: 100px;
    padding-right: 100px;
  }
  @media (max-width: 1399px) {
    column-gap: 80px;
    padding-right: 0px;
  }
  @media (max-width: 991px) {
    column-gap: 50px;
  }
  @media (max-width: 767px) {
    column-gap: 0px;
    column-count: 1;
  }
}

.specification {
  background-image: url(bg-imgs/slice.png);
  background-repeat: repeat-x;
  background-size: auto;
  background-position: 50% 0.8em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #545454;

  &:not(:first-child) {
    margin-top: 1em;
  }

  .left {
    span {
      background-color: #fff;
    }
  }

  .right {
    text-align: right;

    span {
      background-color: #fff;
    }
  }
}

.mobile-btns {
  position: sticky;
  bottom: 50px;
  left: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  @media (max-width: 575px) {
    font-size: 10px;
  }
}

.card-page-top {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  z-index: 100;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div.title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1;
      font-family: $title-font;
      font-weight: 700;
      font-size: 1.5em;
      color: $color-1;
    }

    button {
      margin-left: 1em;
    }
  }
}

.photo {
  position: relative;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
  }

  .indicator {
    position: absolute;
    bottom: 0.25em;
    right: 0.25em;
    width: 0.75em;
    height: 0.75em;
    border-radius: 50%;
    background-color: transparent;
    outline: 2px solid transparent;

    &.online {
      background-color: $green;
      outline: 2px solid #ffffff;
    }
  }
}

/* SECTION 8 - article page */
#sec-8 {
  .article-full {
    .article-page-img {
      width: 500px;
      float: left;
      margin: 0 1.5em 1.5em 0;
      @media (max-width: 1659px) {
        width: 450px;
      }
      @media (max-width: 1399px) {
        width: 350px;
      }
      @media (max-width: 991px) {
        width: 300px;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin: 0 0 1.5em 0;
      }
    }

    .text {
      font-weight: 300;
      font-size: 1.2em;

      p {
        margin-bottom: 1em;
      }
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }
}

/* SECTION 9 - services */
.sec-9 {
  .mobile-title {
    background-color: $color-1;
    color: #fff;
    font-size: 1.5em;

    .container {
      display: flex;
      align-items: center;
      text-align: center;

      svg {
        width: 2em;
        height: 2em;

        .fill {
          fill: #fff;
        }
      }
    }
  }

  nav.service-nav {
    a {
      font-size: 1.4em;
      font-weight: 500;
      color: $color-2;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: #ffffff;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 100%;
      padding: 1.5em 1em;

      &.big {
        font-size: 3em;
        padding: 1.5em;
        transition: 0.3s;
        @media (max-width: 1659px) {
          font-size: 2.5em;
        }
        @media (max-width: 1399px) {
          font-size: 2.25em;
        }
        @media (max-width: 1199px) {
          font-size: 2em;
        }
        @media (max-width: 991px) {
          font-size: 1.75em;
        }

        &:hover {
          color: $color-1;

          .fill {
            fill: $color-1;
          }

          .stroke {
            stroke: $color-1;
          }
        }

        svg {
          transition: 0.3s;

          path {
            transition: 0.3s;
          }
        }

        span {
          margin-left: 1em;
          text-align: center;
        }
      }

      @media (max-width: 1399px) {
        font-size: 1em;
        padding: 1.25em 1em;
      }

      span {
        word-break: break-word;
        @media (max-width: 1659px) {
          font-size: 1em;
          margin-left: 1em;
        }
        @media (max-width: 991px) {
          margin-left: 0.5em;
        }
      }

      img {
        width: 3.75em;
        height: 4.375em;
        @media (max-width: 1399px) {
          width: 3em;
          height: 4em;
        }
        @media (max-width: 1199px) {
          width: 2em;
          height: 3em;
        }
      }

      svg {
        width: 3.75em;
        height: 4.375em;
        fill: none;
        @media (max-width: 1399px) {
          width: 3em;
          height: 4em;
        }
        @media (max-width: 1199px) {
          width: 2em;
          height: 3em;
        }

        .fill {
          fill: $color-2;
        }

        .stroke {
          stroke: $color-2;
          stroke-width: 3.5px;
        }
      }

      &.active {
        color: $color-1;

        svg {
          .fill {
            fill: $color-1;
          }

          .stroke {
            stroke: $color-1;
            stroke-width: 3.5px;
          }
        }
      }
    }
  }

  .service-leftMenu {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    .title {
      background-color: $color-1;
      color: #fff;
      font-size: 1.5em;
      border-radius: 4px;
      display: flex;
      align-items: center;
      text-align: center;

      svg {
        width: 2em;
        height: 2em;

        .fill {
          fill: #fff;
        }
      }
    }
  }
}

.user-card,
.response-card {
  padding: 2em;
  background: #ffffff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 2.5em auto calc(7.5em + 0.5rem);
  grid-gap: 1.25em 3em;
  @media (max-width: 1659px) {
    grid-gap: 1.25em 2em;
  }
  @media (max-width: 1399px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 0;
  }
  @media (max-width: 1199px) {
    padding: 1em;
  }

  &.page {
    display: block;
    padding: 2em 3em;

    .title {
      flex-direction: row;
    }

    @media (max-width: 767px) {
      padding: 2em;
    }
    @media (max-width: 575px) {
      padding: 1em;
    }
  }

  .photo {
    grid-column: 1/2;
    grid-row: 1/3;

    img {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      display: block;
      @media (max-width: 1199px) {
        width: 140px;
        height: 140px;
      }
      @media (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
    }
  }

  .btns {
    grid-column: 1/2;
    grid-row: 3/4;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .title {
    grid-column: 2/5;
    grid-row: 1/2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1399px) {
      flex-direction: column-reverse;
    }
    @media (max-width: 1199px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  .desc {
    grid-column: 2/5;
    grid-row: 2/3;

    .text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.25em;
      height: 2.5em;
      overflow: hidden;
      font-weight: 300;
    }
  }

  .serv-list {
    grid-column: 2/5;
    grid-row: 3/4;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    .serv {
      background-color: $color-3;
      color: $gray-3;
      padding: 0.5em 1em;
      border-radius: 2em;
      font-weight: 500;
      margin-right: 0.75em;
      margin-bottom: 0.5em;
    }
  }
}

.response-card {
  .btns {
    justify-content: start;
    gap: 10px;
  }

  &_in {
    flex: auto;
    grid-template-rows: 2.5em auto 1fr;
  }

  &_out {
    flex: auto;
    grid-template-rows: 2.5em auto 1.5rem;
  }
}

.rating {
  font-size: 0.9em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1399px) {
    justify-content: center;
  }

  img {
    &:not(:first-child) {
      margin-left: 0.2em;
    }

    width: 1.5em;
    height: 1.5em;
  }

  span {
    margin-left: 0.1em;
    color: $gray-4;
  }
}

/* SECTION 10 - user page */
#sec-10 {
  .user-photo {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: 190px;
      object-fit: cover;
      border-radius: 50%;
      @media (max-width: 1659px) {
        height: 150px;
      }
      @media (max-width: 575px) {
        height: 37vw;
      }
    }

    .indicator {
      position: absolute;
      bottom: 8%;
      right: 8%;
      width: 11%;
      height: 11%;
      border-radius: 50%;
      background-color: transparent;
      outline: 2px solid transparent;

      &.online {
        background-color: $green;
        outline: 0.3em solid #ffffff;
      }
    }
  }
}

.review {
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
  padding: 3em;
  display: flex;
  position: relative;
  @media (max-width: 767px) {
    padding: 2em;
  }
  @media (max-width: 575px) {
    padding: 1.5em 1.5em 2em;
  }

  img.photo {
    width: 115px;
    height: 115px;
    border-radius: 50%;
    object-fit: cover;
    @media (max-width: 575px) {
      width: 20vw;
      height: 20vw;
    }
  }

  .rating {
    justify-content: flex-start;
  }

  .date {
    position: absolute;
    top: 1em;
    right: 1.5em;
    @media (max-width: 575px) {
      top: 0.5em;
      right: 0.5em;
    }
  }

  .top {
    position: absolute;
    top: 1em;
    right: 1.5em;
    text-align: right;
    @media (max-width: 575px) {
      top: 0.5em;
      right: 0.5em;
    }
  }

  .btns {
    position: absolute;
    bottom: 1em;
    right: 2em;
    @media (max-width: 575px) {
      bottom: 0.5em;
      right: 0.5em;
    }
  }

  .claiming {
    position: absolute;
    bottom: 1em;
    right: 2em;
    @media (max-width: 575px) {
      bottom: 0.5em;
      right: 0.5em;
    }
  }
}

/*sprite with stars*/
.rate {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: url(bg-imgs/sprite.svg) no-repeat;
    background-size: 100% auto;
    background-position: 0 0;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.2em;

    &:hover,
    &:hover ~ input,
    &:checked,
    &:checked ~ input {
      background: url(bg-imgs/sprite.svg) no-repeat;
      background-size: 100% auto;
      background-position: 0 -1.5em;
    }
  }
}

#write-review {
  .photo {
    img {
      width: 120px;
      height: 120px;
      @media (max-width: 991px) {
        width: 80px;
        height: 80px;
      }
      @media (max-width: 575px) {
        width: 60px;
        height: 60px;
      }
    }

    .indicator {
      bottom: 8%;
      right: 8%;
      width: 11%;
      height: 11%;
    }
  }
}

/* SECTION 11 - Submit an ad */
#sec-11 {
  &.mb-6 {
    @media (max-width: 991px) {
      margin-bottom: 4em;
    }
    @media (max-width: 767px) {
      margin-bottom: 3em;
    }
    @media (max-width: 575px) {
      margin-bottom: 1.5em;
    }
  }

  .faster {
    position: relative;
    width: 100%;
    height: 270px;
    border-radius: 0.25em;
    overflow: hidden;
    @media (max-width: 1659px) {
      height: 230px;
    }
    @media (max-width: 1399px) {
      height: 210px;
    }
    @media (max-width: 1199px) {
      height: 180px;
      font-size: 13px;
    }
    @media (max-width: 991px) {
      display: none;
    }

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .title {
      position: absolute;
      top: 1em;
      left: 10%;
      width: 80%;
      z-index: 2;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-weight: bold;
      font-size: 1.5em;
      @media (max-width: 1659px) {
        left: 5%;
        width: 90%;
      }
      @media (max-width: 1399px) {
        font-size: 1.4em;
      }
    }

    button {
      position: absolute;
      bottom: 1em;
      left: 5%;
      width: 90%;
      z-index: 2;
    }
  }

  form {
    &.not-filled {
      .title-req {
        color: #da1e2a;
      }

      input:not([type="radio"])[required],
      textarea[required] {
        border: 1px solid rgba(#da1e2a, 0.44);
      }
    }

    aside {
      position: sticky;
      top: 80px;
      left: 0px;
      width: 100%;

      nav.contents {
        @media (max-width: 991px) {
          position: sticky;
          top: 50px;
          left: 0px;
          width: 100%;
          background-color: #fff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          padding: 0.5em;
          z-index: 100;
        }

        ol {
          list-style: none;
          counter-reset: li;
          font-size: 1.5em;
          padding: 0;
          @media (max-width: 1399px) {
            font-size: 1.4em;
          }
          @media (max-width: 1199px) {
            font-size: 1.2em;
          }
          @media (max-width: 991px) {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5em;
          }

          li {
            padding-left: 2.25em;
            margin-bottom: 1em;
            position: relative;
            @media (max-width: 1199px) {
              padding-left: 2em;
            }
            @media (max-width: 991px) {
              padding-left: 0px;
              margin-bottom: 0px;
            }

            &:not(:first-child) {
              @media (max-width: 991px) {
                margin-left: 1em;
              }

              a::after {
                content: "";
                position: absolute;
                left: calc(0.75em - 2px);
                bottom: 100%;
                width: 4px;
                height: 1em;
                background-color: $color-3;
                @media (max-width: 991px) {
                  left: unset;
                  right: 100%;
                  bottom: calc(0.75em - 2px);
                  height: 4px;
                  width: 1em;
                }
              }
            }

            a {
              border-bottom: 4px solid transparent;
              transition: 0.2s;
              line-height: 1.5em;
              @media (max-width: 991px) {
                border-bottom: unset;
              }

              &::before {
                content: counter(li);
                counter-increment: li;
                position: absolute;
                left: 0;
                width: 1.5em;
                height: 1.5em;
                border-radius: 50%;
                border: 2px solid $color-3;
                background-color: $color-3;
                color: #fff;
                font-family: $title-font;
                font-weight: 700;
                @include flex-center();
                transition: 0.2s;
              }

              &.active,
              &:hover,
              &:focus,
              &:active {
                border-bottom: 4px solid $color-1;
                @media (max-width: 991px) {
                  border-bottom: unset;
                }

                &:before {
                  border: 2px solid $color-1;
                  background-color: #fff;
                  color: $color-1;
                }
              }

              @media (max-width: 991px) {
                width: 1.5em;
                height: 1.5em;
                display: block;
                span {
                  display: none;
                }
              }
            }

            &.filled {
              a {
                &::before {
                  background-color: $color-1;
                  border: 2px solid $color-1;
                  color: transparent;
                  background-image: url(bg-imgs/mark3.svg);
                  background-repeat: no-repeat;
                  background-position: 55% 55%;
                  background-size: 0.8em;
                }

                &::after {
                  background-color: $color-1;
                }
              }
            }
          }
        }
      }
    }

    .mob-indicator {
      @media (min-width: 992px) {
        display: none;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2em;

      div {
        position: relative;
        font-size: 2em;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        border: 2px solid $color-3;
        background-color: $color-3;
        color: #fff;
        font-family: $title-font;
        font-weight: 700;
        @media (max-width: 575px) {
          font-size: 1.5em;
        }
        @include flex-center();

        &.active {
          border: 2px solid $color-1;
          background-color: #fff;
          color: $color-1;
        }

        &:not(:first-child) {
          margin-left: 1em;

          &::after {
            content: "";
            position: absolute;
            right: calc(100% + 2px);
            bottom: calc(0.75em - 4px);
            height: 4px;
            width: 1em;
            background-color: $color-3;
          }
        }
      }
    }

    fieldset {
      @media (max-width: 991px) {
        &[data-show="false"] {
          display: none;
        }
        &[data-show="true"] {
          display: block;
        }
      }
      @media (max-width: 767px) {
        .title,
        .title-req {
          font-size: 1.2em;
          font-weight: 500;
        }
      }
    }

    .frame {
      @media (max-width: 991px) {
        box-shadow: unset;
      }
    }
  }
}

/* SECTION 12 - personal PersonalAccount */
main.account {
  background: url(bg-imgs/bg2.png) no-repeat center bottom $gray-6;
  background-size: 100% auto;
  @media (max-width: 991px) {
    background: #fff;
  }
}

#sec-12 {
  nav.menu {
    @media (max-width: 991px) {
      width: fit-content;
      margin: 0 auto;
    }

    ul {
      list-style: none;
      padding-left: 0px;

      li {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 1.5em;
        }

        img {
          width: 1.5em;
          margin-right: 1em;
        }

        a,
        button {
          font-family: $title-font;
          font-size: 1.5em;
          font-weight: 600;
          color: #44494c;

          &.active {
            color: $color-1;
          }

          @media (max-width: 1399px) {
            font-size: 1.25em;
          }
          @media (max-width: 991px) {
            font-size: 1.75em;
          }
          @media (max-width: 575px) {
            font-size: 1.5em;
            font-weight: 500;
          }
        }

        .count {
          width: 1.4em;
          height: 1.4em;
          background-color: $color-1;
          color: #fff;
          font-family: $title-font;
          font-weight: 600;
          font-size: 1.1em;
          @include flex-center();
          margin-left: 1em;
          border-radius: 50%;
        }
      }
    }
  }

  .card-midi {
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.1));
  }

  input:not([type="checkbox"]):not([type="radio"]),
  textarea,
  .inp {
    padding: 0.8em 0.85em;
  }

  @media (max-width: 767px) {
    .review {
      padding: 2em 2em 3em 2em;

      .top {
        top: 0.5em;
        right: 0.5em;

        .rating {
          img {
            width: 1em;
            height: 1em;
          }
        }
      }
    }
  }
}

.form-profile {
  .upload__image-wrapper {
    border: unset;
    padding: 0px;

    .imgs-box {
      .image-item {
        width: 170px;
        height: 170px;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        &__btn-wrapper {
          background-color: rgba(0, 0, 0, 0.35);
          @include flex-center();
          flex-direction: column;

          button {
            width: fit-content;
            display: flex;

            img {
              width: 0.9em;
              height: 0.9em;
            }
          }
        }
      }
    }
  }
}

.input-tag {
  position: relative;

  input {
    position: relative;
    z-index: 1;
  }

  button {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 2em;
    z-index: 2;
    font-size: 1.5em;
    color: #c59c7e;
    @include flex-center();
  }
}

.tag-list {
  display: flex;
  flex-wrap: wrap;

  .tag {
    @include flex-center();
    background-color: $gray-5;
    padding: 0.6em 1em;
    border-radius: 2em;
    color: $gray-3;
    font-weight: 500;
    margin: 0 0.5em 0.75em 0;

    button {
      @include flex-center();
      margin-left: 0.5em;
    }
  }
}

.conversations {
  height: calc(100vh - 184px);
  display: flex;
  flex-direction: column;
  margin-top: -48px;

  h4 {
    background-color: $white;
    position: relative;
    z-index: 100;
    padding: 1rem 0;
    box-shadow: 0 5px 5px 0 rgb(0, 0, 0, 10%);
    margin: 0;
  }

  // .conversations__list
  &__list {
    background-color: $white;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @media all and (max-width: 1399px) {
    margin-top: -24px;
  }

  @media all and (max-width: 1199px) {
    height: calc(100vh - 205px);
  }

  @media all and (max-width: 991px) {
    height: calc(100vh - 90px);
    margin-top: 0;
  }

  @media all and (max-width: 767px) {
    height: calc(100vh - 134px);
  }

  @media all and (max-width: 575px) {
    height: calc(100vh - 100px);
  }
}

.conversation-list {
}

.conversation-item {
  border-top: 1px solid $color-3;
  border-bottom: 1px solid $color-3;
  padding: 2em 3em 2em 4em;
  display: flex;
  align-items: stretch;
  position: relative;
  @media (max-width: 1399px) {
    padding: 1.5em 2em;
  }
  @media (max-width: 1199px) {
    padding: 1em;
  }
  @media (max-width: 991px) {
    border: 1px solid $color-3;
  }
  @media (max-width: 575px) {
    margin-right: -12px;
    margin-left: -12px;
    align-items: flex-start;
  }

  .link-to-chat {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.unread {
    background-color: #f1f7fa;
  }

  .photo {
    width: fit-content;
    margin-right: 2em;
    position: relative;
    z-index: 1;
    @media (max-width: 1199px) {
      margin-right: 1em;
    }

    img {
      width: 115px;
      height: 115px;
      @media (max-width: 767px) {
        width: 100px;
        height: 100px;
      }
      @media (max-width: 575px) {
        width: 75px;
        height: 75px;
      }
    }

    .indicator {
      bottom: 8%;
      right: 8%;
      width: 11%;
      height: 11%;
      background-color: $gray-4;
      outline: 0.3em solid #ffffff;
      @media (max-width: 575px) {
        width: 10px;
        height: 10px;
      }

      &.online {
        background-color: $green;
        outline: 0.3em solid #ffffff;
      }
    }
  }

  .main {
    width: calc(100% - (115px + 10em));
    position: relative;
    z-index: 1;
    @media (max-width: 1199px) {
      width: calc(100% - (115px + 8em));
    }
    @media (max-width: 767px) {
      width: calc(100% - (100px + 8em));
    }
    @media (max-width: 575px) {
      width: calc(100% - (75px + 4em));
    }

    .message {
      font-size: 0.9em;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .reply {
      font-size: 0.9em;
      font-weight: 300;
      display: flex;
      align-items: center;

      img {
        width: 2em;
        height: 2em;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.5em;
      }

      .message {
        font-size: 0.9em;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 4.5em);
      }

      .mark {
        width: 2em;
        background: none;
        padding: 0px;
        @include flex-center();

        i {
          font-size: 1.8em;
          color: $color-1;
        }

        img {
          max-width: 100%;
          object-fit: scale-down;
          margin-right: 0px;
        }
      }
    }
  }

  .new {
    margin-left: 1em;
    width: 2em;
    position: relative;
    z-index: 1;
    @include flex-center();
    flex-direction: column;
    @media (max-width: 575px) {
      position: absolute;
      bottom: 0px;
      right: 0px;
      margin-left: 0px;
      font-size: 10px;
    }

    div {
      font-size: 1.1em;
      font-family: $title-font;
      font-weight: bold;
      background-color: $color-1;
      color: #fff;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      @include flex-center();
    }
  }

  .end {
    margin-left: 2em;
    width: 3em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
    @media (max-width: 1199px) {
      margin-left: 1em;
    }
    @media (max-width: 575px) {
      height: 75px;
      margin-left: 0px;
    }

    .btn-del {
      opacity: 0;
      transition: 0.2s ease;
      @media (max-width: 1024px) {
        opacity: 1;
      }

      svg {
        width: 1.1em;
        height: 1.3em;
        fill: none;

        path {
          fill: $gray-4;
        }
      }

      i {
        font-size: 1.4em;
        color: $gray-4;
      }

      &:hover,
      &:focus {
        svg {
          path {
            fill: $color-1;
          }
        }

        i {
          color: $color-1;
        }
      }

      &:active {
        i {
          color: $color-2;
        }
      }
    }

    .btn-notice {
      background-color: transparent;
      opacity: 0;
      transition: 0.2s ease;
      @media (max-width: 1024px) {
        opacity: 1;
      }

      svg {
        width: 1.5em;
        height: 1.5em;
      }

      i {
        font-size: 1.4em;
        color: $gray-4;
      }

      &:hover,
      &:focus {
        i {
          color: $color-1;
        }
      }

      &:active {
        i {
          color: $color-2;
        }
      }
    }
  }

  &:hover {
    background-color: #f1f7fa;

    .end {
      .btn-notice,
      .btn-del {
        opacity: 1;
      }
    }
  }
}

.chat-top {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  @media (max-width: 767px) {
    background-color: #fff;
    border-bottom: 1px solid $color-3;
    //padding-bottom: 1em;
  }
  //@media (max-width: 575px) {
  //  margin-left: -12px;
  //  margin-right: -12px;
  //  padding-bottom: .5em;
  //  padding-top: .5em;
  //  position: sticky;
  //  top: 10px;
  //  left: 0;
  //  z-index: 10;
  //}

  a {
    svg {
      width: 0.75em;
      height: 1.4em;
      @media (max-width: 767px) {
        width: 0.5em;
        height: 1em;
      }
    }
  }

  .photo {
    img {
      width: 50px;
      height: 50px;
    }
  }

  .btns {
    display: flex;
    gap: 10px;
    height: 35px;

    button {
      border: 2px solid $gray-3;
      border-radius: 30px;
      padding: 0.25em 2em;
    }
  }
}

.messages {
  position: relative;
  justify-content: flex-start;
  height: calc(100vh - 184px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: -48px;

  @media all and (max-width: 1399px) {
    margin-top: -24px;
  }

  @media all and (max-width: 1199px) {
    height: calc(100vh - 205px);
  }

  @media all and (max-width: 991px) {
    height: calc(100vh - 90px);
    margin-top: 0;
  }

  @media all and (max-width: 767px) {
    height: calc(100vh - 134px);
  }

  @media all and (max-width: 575px) {
    height: calc(100vh - 100px);
  }

  // .messages-list
  &-list {
    background-color: transparent;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    flex-shrink: 1;
    flex-grow: 1;

    @media all and (max-width: 991px) {
      //margin-top: 50px;
      padding: 15px 0;
    }

    // .messages-list_indent
    &_indent {
      margin-top: 50px;
    }

    // .messages-list__loader
    &__loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
    }

    .chat-date {
      font-family: $title-font;
      font-weight: 700;
      font-size: 1.2em;
      text-align: center;
      color: $gray-3;
      padding: 0.5em;
    }

    .message {
      padding: 1.25em 1em 1.25em 4em;
      display: flex;
      align-items: flex-start;
      position: relative;
      @media (max-width: 1399px) {
        padding: 1.25em 1em 1.25em 2em;
      }
      @media (max-width: 991px) {
        padding: 1em 20% 1em 1em;
      }
      @media (max-width: 575px) {
        padding: 0.5em 20% 0.5em 1em;
      }

      &.editable {
        background-color: rgba($color-1, 0.15);
      }

      &.unread {
        background-color: #f1f7fa;

        .photo {
          .indicator,
          .indicator.online {
            outline: 0.3em solid #f1f7fa;
          }
        }
      }

      .photo {
        width: fit-content;
        margin-right: 2em;
        position: relative;
        z-index: 1;
        @media (max-width: 1199px) {
          margin-right: 1em;
        }
        @media (max-width: 991px) {
          display: none;
        }

        img {
          width: 115px;
          height: 115px;
          @media (max-width: 1199px) {
            width: 90px;
            height: 90px;
          }
          @media (max-width: 767px) {
            width: 60px;
            height: 60px;
          }
        }

        .indicator {
          bottom: 8%;
          right: 8%;
          width: 11%;
          height: 11%;
          background-color: $gray-4;
          outline: 0.3em solid #ffffff;

          &.online {
            background-color: $green;
            outline: 0.3em solid #ffffff;
          }
        }
      }

      .main {
        border: unset !important;
        width: calc(100% - (115px + 11em));
        @media (max-width: 1199px) {
          width: calc(100% - (90px + 10em));
        }
        @media (max-width: 991px) {
          background-color: $gray-6;
          width: auto;
          padding: 1em;
          border-radius: 4px;
        }

        // .main_mobile
        &_mobile {
          position: relative;
          background-color: rgba(20, 100, 146, 0.5) !important;
          color: black !important;
          max-width: 200px;

          .text {
            font-weight: 500 !important;
            -moz-user-select: none;
            -khtml-user-select: none;
            user-select: none;
            width: 100%;
            word-break: break-word;
          }
        }

        // mobile edit message color
        &.selected {
          background-color: rgba(197, 156, 126, 0.3) !important;
        }

        &.active {
          .main__dropdown {
            display: flex;
          }
        }

        .name {
          font-size: 1.3em;
          font-family: $title-font;
          font-weight: 700;
          margin-bottom: 0.5em;
          @media (max-width: 991px) {
            display: none;
          }
        }

        .text {
          font-size: 0.9em;
          font-weight: 300;

          p {
            margin-bottom: 1em;
          }
        }
      }

      &.my {
        @media (max-width: 991px) {
          padding: 1em 1em 1em 20%;
          .main {
            background-color: #f1f7fa;
          }
        }
        @media (max-width: 575px) {
          padding: 0.5em 1em 0.5em 20%;
        }
      }

      .mark {
        width: 2em;
        background: none;
        padding: 0px;
        @include flex-center();

        img {
          max-width: 100%;
          object-fit: scale-down;
          margin-right: 0px;
          @media (max-width: 991px) {
            height: 100%;
          }
        }

        i {
          font-size: 1.6em;
          color: $color-1;
          line-height: 0.8em;
        }

        @media (max-width: 991px) {
          position: absolute;
          bottom: 1.2em;
          right: 4.5em;
          height: 0.75em;
        }
      }

      .date {
        font-size: 0.9em;
        font-weight: 500;
        width: 3em;
        margin-left: 1em;
        @media (max-width: 991px) {
          position: absolute;
          bottom: 1.2em;
          right: calc(20% + 1em);
        }
      }

      &.my {
        @media (max-width: 991px) {
          .date {
            right: 2em;
          }
        }
      }

      .btns {
        display: flex;
        justify-content: space-around;
        width: 4em;
        margin-left: 1em;
        @media (max-width: 1199px) {
          width: 3em;
        }
        @media (max-width: 991px) {
          display: none;
        }

        button {
          width: 1em;
          height: 1em;
          opacity: 0;
          transition: 0.2s ease;
          @media (max-width: 1024px) {
            opacity: 1;
          }

          svg {
            width: 1em;
            height: 1em;
            fill: none;

            path {
              transition: 0.2s ease;
              fill: $gray-4;
            }
          }

          i {
            font-size: 1.1em;
            color: $gray-4;
          }

          &:hover,
          &:focus {
            svg {
              path {
                fill: $color-1;
              }
            }

            i {
              color: $color-1;
            }
          }

          &:active {
            i {
              color: $color-2;
            }
          }
        }
      }

      &:hover {
        .btns {
          button {
            opacity: 1;
          }
        }
      }
    }
  }

  .send-message {
    //position: absolute;
    //left: 0;
    //bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $gray-5;
    background-color: #fff;
    @media (max-width: 575px) {
      left: 0;
      z-index: 10;
    }

    .input-file {
      width: 3em;
      height: 3em;
    }

    button[type="submit"] {
      img {
        width: 2em;
      }
    }
  }

  // .messages__actions
  &__actions {
    position: absolute;
    left: 0;
    right: 0;
    top: 67px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    width: 100%;
    padding: 0 15px;
    background-color: white;
    border-top: 1px solid $color-3;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    transition: height 0.2s linear, opacity 0.2s linear, overflow 0.2s linear;

    @media all and (max-width: 991px) {
      top: 70px;
    }

    @media all and (max-width: 765px) {
      top: 71px;
    }

    @media all and (max-width: 575px) {
      top: 63px;
    }

    &.show {
      height: 50px;
      overflow: visible;
      opacity: 1;
    }

    .btns {
      display: flex;
      gap: 10px;
      height: 35px;

      button {
        padding: 0 30px;
        border: 2px solid $gray-3;
        border-radius: 30px;
      }

      button:hover {
        background-color: rgba($color-1, 0.1);
      }
    }
  }
}

.chat-actions-icon {
  width: 24px;
  height: 24px;
}

.choose-icon {
  width: 24px;
  height: 24px;
  fill: $color-1;
}

.dropdown-toggle {
  padding: 0px 0.5em;

  &::after {
    content: unset;
  }

  svg {
    fill: none;
    width: 0.4em;
    height: 1.7em;

    circle {
      fill: $gray-4;
    }
  }
}

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0;
  max-width: 500px;
  max-height: 300px;
  overflow-y: auto;

  .dropdown-item {
    color: #146492;
    padding: 0.5em 2em;
    cursor: pointer;
    white-space: nowrap;
    text-align: left;
    background-color: transparent;

    &:hover,
    &:active {
      background-color: $color-3;
    }
  }
}

.tabs {
  list-style: none;
  padding-left: 0px;
  display: flex;
  align-items: center;

  li {
    &:not(:first-child) {
      margin-left: 2em;
      @media (max-width: 575px) {
        margin-left: 1em;
      }
    }

    button {
      font-size: 1.25em;
      font-weight: 600;
      font-family: $title-font;
      border-bottom: 4px solid transparent;

      &:hover,
      &:focus,
      :active {
        color: $color-1;
      }

      &.active {
        color: $color-1;
        border-bottom: 4px solid $color-2;
      }
    }
  }
}

.form-response {
  font-size: 1.1em;
  @media (max-width: 1399px) {
    font-size: 1em;
  }
  @media (max-width: 1199px) {
    font-size: 0.9em;
  }

  input[type="number"] {
    padding: 1em 1.5em !important;
    min-width: 200px;
    @media (max-width: 1399px) {
      padding: 0.5em 1em !important;
      min-width: 100%;
    }
  }

  .custom-select {
    width: fit-content;
    @media (max-width: 1399px) {
      width: 100%;
      margin-top: 0.5em;
    }

    .btn {
      padding: 1em !important;
      @media (max-width: 1399px) {
        padding: 0.5em 1em !important;
      }
    }
  }
}

/* SECTION 13 - hypotec */
.sec-13 {
  .item {
    font-size: 1.3em;
    @media (max-width: 1659px) {
      font-size: 1.1em;
    }
    @media (max-width: 1399px) {
      font-size: 1em;
    }
    @media (max-width: 575px) {
      font-size: 0.8em;
    }

    .img {
      width: 100%;
      height: 180px;
      object-fit: scale-down;
      margin-bottom: 1em;
      @media (max-width: 1659px) {
        height: 150px;
      }
      @media (max-width: 1399px) {
        height: 125px;
      }
      @media (max-width: 575px) {
        height: 25vw;
        margin-bottom: 0.5em;
      }
    }

    .link {
      color: $blue;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.2em;
      height: 2.4em;
      word-break: break-all;
    }
  }
}

/* Login & Registration */
form.entrance {
  background-color: #fff;
  border: 1px solid $gray-5;
  padding: 3em 20% 4em;

  input:not([type="checkbox"]):not([type="radio"]),
  textarea,
  .inp {
    padding: 0.8em 0.85em;
  }

  @media (max-width: 1399px) {
    padding: 3em 15% 4em;
  }
  @media (max-width: 1199px) {
    padding: 2em 3em 3em;
  }
  //@media (max-width: 991px) {
  //  padding: 0em;
  //  border: none;
  //}
}

.password {
  position: relative;

  input {
    position: relative;
    z-index: 1;
  }

  button {
    position: absolute;
    top: 0px;
    right: 1em;
    height: 100%;
    z-index: 2;
    width: 2.5em;

    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
}

footer {
  background-color: #373c3f;
  color: #fff;
  height: 400px;
  padding: 3em 0;
  @media (max-width: 991px) {
    height: 365px;
  }
  @media (max-width: 767px) {
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: sticky;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    padding: 0;
  }

  hr {
    height: 2px;
    width: 100%;
    background-color: #fff;
    opacity: 1;
    border: none;
    margin: 1.75em 0;
    @media (max-width: 1199px) {
      margin: 1.5em 0;
      height: 1px;
    }
  }

  .logo {
    display: block;
    max-width: 100%;
    margin: 0 auto 1em;
    @media (max-width: 1199px) {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin: 0 1em 0 0;
    }
  }

  nav {
    &.desktop {
      ul {
        list-style: none;
        padding: 0px;

        li {
          &:not(:first-child) {
            margin-top: 1.5em;
            @media (max-width: 1199px) {
              margin-top: 1em;
            }
          }

          a {
            text-transform: uppercase;
            font-family: $title-font;
            font-size: 1.25em;
            font-weight: 500;
            @media (max-width: 1199px) {
              font-size: 1em;
            }
          }
        }
      }
    }

    &.mobile {
      height: 100%;

      ul {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
        padding: 0px;

        li {
          a {
            svg {
              fill: none;

              path {
                fill: #c4c4c4;
              }

              &.type-2 {
                path {
                  fill: none;
                  stroke: #c4c4c4;
                  stroke-width: 3px;
                  stroke-linecap: square;
                  stroke-linejoin: round;
                }
              }
            }

            &.active {
              svg {
                path {
                  fill: $color-2;
                }

                &.type-2 {
                  path {
                    fill: none;
                    stroke: $color-2;
                  }
                }
              }
            }
          }
          a.heart {
            svg {
              path {
                fill: $red;
              }

              &.type-2 {
                path {
                  fill: none;
                  stroke: $red;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* SWIPER SLIDERS */
.swiper-4,
.swiper-3 {
  overflow: hidden;
  padding: 1em 0 3em;

  .swiper-button-prev,
  .swiper-button-next {
    width: 1.5em;
    height: 3em;

    &::after {
      content: unset;
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  .swiper-button-next {
    right: -2em;
  }

  .swiper-button-prev {
    left: -2em;
  }
}

.swiper-button-prev,
.swiper-button-next {
  &::after {
    content: unset;
  }

  svg {
    width: 1.5em;
    height: 3em;
    fill: none;

    line {
      stroke: $color-1;
      stroke-width: 3px;
    }
  }
}

.swiper-pagination {
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-bullet {
    width: 1em;
    height: 1em;
    background-color: $color-3;
    opacity: 1;

    &:not(:first-child) {
      margin-left: 0.75em;
    }

    &-active {
      background-color: $color-2;
    }
  }
}

.swiper-container-fade {
  .swiper-slide {
    opacity: 0 !important;
    transition: 0.1s;
    z-index: 1;

    &-active {
      opacity: 1 !important;
      z-index: 2;
    }
  }
}

.main-slider,
.thumbs-slider {
  .swiper-button-prev,
  .swiper-button-next {
    svg {
      line {
        stroke: $color-2;
        stroke-width: 4px;
      }
    }
  }
}

.main-slider {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);

  .swiper-slide {
    width: 100%;
    height: 500px;
    position: relative;
    @media (max-width: 1659px) {
      height: 460px;
    }
    @media (max-width: 1399px) {
      height: 380px;
    }
    @media (max-width: 1199px) {
      height: 325px;
    }
    @media (max-width: 991px) {
      height: 380px;
    }
    @media (max-width: 767px) {
      height: 330px;
    }
    @media (max-width: 575px) {
      height: 60vw;
    }

    button {
      position: absolute;
      top: 1em;
      right: 1em;
      @media (max-width: 991px) {
        right: 2em;
      }
      @media (max-width: 575px) {
        width: 1.5em;
      }
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .swiper-button-prev {
    left: 1em;
  }

  .swiper-button-next {
    right: 1em;
  }

  @media (max-width: 1199px) {
    .swiper-button-prev,
    .swiper-button-next {
      width: 1.5em;
    }
  }
  @media (max-width: 767px) {
    .swiper-button-prev {
      left: 0em;
    }
    .swiper-button-next {
      right: 0em;
    }
    .swiper-button-prev,
    .swiper-button-next {
      background-color: rgba(68, 73, 76, 0.28);
      width: 2em;
      padding: 0.5em;
      top: 0px;
      margin-top: 0px;
      bottom: 0px;
      height: 100%;

      svg {
        line {
          stroke: #fff;
          stroke-width: 4px;
        }
      }
    }
  }
}

.work-example {
  width: 100%;
  height: 90px;
  object-fit: cover;
  @media (max-width: 575px) {
    height: 16vw;
  }
}

.thumbs-slider {
  padding: 3px 3px;
  overflow: hidden;

  .swiper-slide-thumb-active {
    outline: 3px solid $color-2;
  }

  .swiper-slide {
    img {
      width: 50px;
      height: 300px;
      object-fit: cover;
      @media (max-width: 575px) {
        height: 15vw;
      }
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  @media (max-width: 1199px) {
    .swiper-button-prev,
    .swiper-button-next {
      width: 1.5em;
    }
  }
  @media (max-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

.swiper-home {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .swiper-pagination {
    bottom: 6.5em;
  }
}

.swiper-container-fade {
  .swiper-slide {
    transition: opacity 1s ease-in;
  }
}

/* breadcrumbs */
.breadcrumb {
  font-size: 0.8em;
  margin-bottom: 0px;
}

/* pagination */
.pagination {
  justify-content: center;

  .page-item {
    width: 2em;
    height: 1.9em;
    @include flex-center();

    .page-link {
      width: 100%;
      height: 100%;
      color: $gray-3;
      border: none;
      padding: 0px;
      @include flex-center();
    }

    &.active {
      .page-link {
        background-color: $color-1;
        color: #fff;
      }
    }
  }
}

/* MODAL WINDOWS */
#header-menu {
  nav {
    ul {
      list-style: none;
      padding-left: 0px;
    }
  }
}

.modal {
  z-index: $modal-z-index;

  &-backdrop {
    z-index: $modal-z-index - 1;
  }

  .modal-content {
    background-color: #fff;
    border-radius: 0px;
    border: none;
  }

  .modal-body {
    padding: 2em;
  }

  &__exit-with-account {
    .modal-body {
      padding: 0 2em 2em 2em;
    }
  }
}

.modal-header {
  border: none;
}

.offcanvas {
  width: 450px;
  z-index: $offcanvas-z-index;

  @media (max-width: 575px) {
    width: 100%;
  }

  &-backdrop {
    z-index: $offcanvas-z-index - 1;
  }

  &-header {
    margin-bottom: 25px;
  }

  &-body {
    padding: 2em;

    nav {
      ul {
        li {
          text-align: center;

          &:not(:first-child) {
            margin-top: 1em;
          }

          a {
            font-size: 1.3em;
            color: $color-2;
            text-align: center;

            &.active {
              color: $color-1;
            }
          }
        }
      }
    }
  }

  &-footer {
    padding: 1em;
    background: #ffffff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  }

  // custom offcanvas
  &-ymap,
  &-cards {
    top: auto !important;
    width: 450px !important;
    height: calc(100vh - 410px);

    @media (max-width: 1659px) {
      height: calc(100vh - 350px);
    }
    @media (max-width: 1399px) {
      height: calc(100vh - 340px);
    }
    @media (max-width: 1199px) {
      height: calc(100vh - 310px);
    }
  }

  &-ymap {
    z-index: $ymaps-z-index;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &-cards {
    @media (max-width: 991px) {
      height: 100vh;
      top: 0;
    }
    @media (max-width: 767px) {
      height: calc(100vh - 50px);
      bottom: 50px;
    }
  }

  &-catalog {
    z-index: $offcanvas-z-index + 1;
  }

  &-menu {
    // offcanvas-menu__list
    &__list {
      list-style: none;
      padding: 0;
    }
  }
}

.btn-close {
  position: absolute;
  z-index: 100 !important;
  top: 1em;
  right: 1em;
  width: 2em;
  height: 2em;
  padding: 0px;
  display: flex;
  opacity: 1;
  background: unset;
  @media (max-width: 1399px) {
    top: 0.5em;
    right: 0.5em;
    width: 1.25em;
    height: 1.25em;
  }
  @media (max-width: 767px) {
    top: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: none;

    path {
      stroke: $color-2;
      stroke-width: 1.5px;
      stroke-linecap: square;
      stroke-linejoin: round;
    }
  }

  &:hover {
    svg {
      path {
        stroke: $color-1;
      }
    }
  }
}

.y-maps {
  width: 100%;
  height: 100%;
}

.catalog {
  position: relative;

  &.shown-map {
    & nav[aria-label="breadcrumb"] {
      display: none;
    }

    & .catalog__title {
      margin-top: 72px;
      margin-bottom: 0;

      @media (max-width: 991px) {
        margin-top: 22px;
        margin-bottom: 10px;
      }
    }
  }

  &__ymaps-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 410px);
    z-index: $ymaps-z-index;

    @media (max-width: 1659px) {
      height: calc(100vh - 350px);
    }
    @media (max-width: 1399px) {
      height: calc(100vh - 340px);
    }
    @media (max-width: 1199px) {
      height: calc(100vh - 310px);
    }
    @media (max-width: 991px) {
      height: calc(100vh - 270px);
      padding-left: 0;
    }
    @media (max-width: 767px) {
      height: calc(100vh - 260px);
    }
    @media (max-width: 575px) {
      height: calc(100vh - 210px);
    }
  }

  &__ymaps {
    width: 100%;
    height: 100%;
    padding-left: 449px;

    @media (max-width: 991px) {
      padding-left: 0;
    }
    @media (max-width: 767px) {
      padding-bottom: 50px;
    }
  }
}

.city-container {
  position: relative;
}

// custom modals
.city-popup {
  display: none;
  padding: 20px;
  width: 340px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 55px;
  font-size: 14px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 4px 4px rgb(0 0 0 / 4%),
    0 20px 40px rgb(0 0 0 / 8%);
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 4px 4px rgb(0 0 0 / 4%),
    0 20px 40px rgb(0 0 0 / 8%);
  transition: display 2s linear;

  @media (max-width: 991px) {
    right: auto;
    left: -55px;
  }

  &.show {
    display: block;
  }

  &::before {
    content: "\A";
    border-color: transparent transparent #ddd;
    border-style: solid;
    border-width: 10px 15px;
    position: absolute;
    right: 25px;
    top: -20px;

    @media (max-width: 991px) {
      right: auto;
      left: 65px;
    }
  }

  &__header {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 18px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}

.catalog__search {
  position: relative;

  & input {
    width: 100%;
  }
}

.advertise__address {
  position: relative;
}

.react-dadata__suggestions {
  position: absolute;
  background-color: white;
  width: 100%;
  top: 44px;
  left: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid rgb(224, 224, 224);
  border-top: none;
  z-index: $select-z-index;
}

.custom-select {
  width: 100%;
  max-width: 100%;
  position: relative;

  &__toggle {
    width: 100%;
    position: relative;
    z-index: 1;
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      span:not(:empty):before {
        content: ":";
      }
    }

    svg {
      width: 1.4rem;
      height: 0.75rem;
      fill: none;
      @media (max-width: 767px) {
        width: 1.2em;
      }
      @media (max-width: 575px) {
        width: 1em;
      }

      line {
        stroke: $gray-2;
        stroke-width: 2px;
        @media (max-width: 575px) {
          stroke-width: 3px;
        }
      }
    }

    &.btn {
      svg {
        line {
          stroke: #fff;
        }
      }
    }
  }

  &_prepayment {
    max-width: 13em;
  }

  &_create-response-ad {
    border: 1px solid $gray-4;
    padding: 0.5em;
    border-radius: 5px;
    button {
      div {
        text-transform: capitalize;
      }
    }
  }

  .dropdown-toggle::after {
    content: unset;
  }

  .dropdown-list {
    display: none;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    min-width: 100%;
    max-width: 500px;
    max-height: 300px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: $select-z-index;
    padding: 0.5rem 0;

    &[data-alignment="right"] {
      left: unset;
      right: 0;
    }

    label.radio-line {
      display: block;
      position: relative;
      z-index: 1;
      text-align: left;
      justify-content: flex-start;
      color: $color-1;
      padding: 0.5em 2em;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        input {
          background-color: $color-3;
        }
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        cursor: pointer;
        background: transparent;
        border-radius: 0px;
        transition: 0.2s;

        &:checked {
          background: $color-3;
        }
      }
    }

    &__inner {
      max-height: 300px;
      overflow-y: scroll;
    }

    form {
      width: 100%;
    }

    &__search {
      width: 100%;
      outline: none;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid rgb(224, 224, 224);
    }
  }

  &_city {
    .custom-select__toggle_city {
      div {
        color: $color-2;
      }
    }

    .dropdown-list {
      width: 260px;
      padding: 0;

      &[data-alignment="right"] {
        @media (max-width: 991px) {
          right: unset;
          left: 0;
        }
      }
    }
  }

  &_orderby {
    z-index: $ymaps-z-index - 1;

    .dropdown-list {
      z-index: $ymaps-z-index - 1;
    }
  }

  &_district {
    .dropdown-list {
      &__inner {
        padding: 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .checkbox-line {
          gap: 10px;
        }
      }
    }
  }

  &.show .dropdown-list {
    display: block;
  }
}

.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 3rem;

  &__title {
    text-align: center;
    margin: 0;
  }

  &__link {
    text-align: center;
    padding: 0;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

// loader
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.service-card {
  padding: 2em;
  background: #ffffff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));
  margin: 1em 0 1em 0;
  @media (max-width: 1659px) {
    grid-gap: 1.25em 2em;
  }
  @media (max-width: 1399px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
  @media (max-width: 1199px) {
    padding: 1em;
  }

  &.page {
    display: block;
    padding: 2em 3em;

    .title {
      flex-direction: row;
    }

    @media (max-width: 767px) {
      padding: 2em;
    }
    @media (max-width: 575px) {
      padding: 1em;
    }
  }

  .photo {
    grid-column: 1/2;
    grid-row: 1/3;

    img {
      width: 160px;
      height: 160px;
      display: block;
      @media (max-width: 1199px) {
        width: 140px;
        height: 140px;
      }
      @media (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
    }
  }

  .btns {
    grid-column: 1/2;
    grid-row: 3/4;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .title {
    grid-column: 2/5;
    grid-row: 1/2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1399px) {
      flex-direction: column-reverse;
    }
    @media (max-width: 1199px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  .desc {
    grid-column: 2/5;
    grid-row: 2/3;

    .text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.25em;
      height: 2.5em;
      overflow: hidden;
      font-weight: 300;
    }
  }

  .serv-list {
    grid-column: 2/5;
    grid-row: 3/4;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;

    .serv {
      background-color: $color-3;
      color: $gray-3;
      padding: 0.5em 1em;
      border-radius: 2em;
      font-weight: 500;
      margin-right: 0.75em;
      margin-bottom: 0.5em;
    }
  }

  .delete {
    width: max-content;
  }
}

.submit-alert {
  margin: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  border: none;
  z-index: 10;
  border-radius: 0;
  text-align: center;
  @media (max-width: 767px) {
    bottom: 50px;
  }

  span {
    font-weight: bold;
  }
}

.auth-error {
  display: flex;
  padding: 5em 0;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.show-more {
  overflow: auto;
  height: 40em;
}

.send-msg {
  align-self: center;
  cursor: pointer;
  width: 24px;
  height: 24px;

  & > svg {
    width: 24px;
    height: 24px;

    & > path {
      fill: #146492;
    }
  }

  &:disabled {
    svg > path {
      fill: $gray-3;
    }
  }
}

.mobile-item {
  .dropdown-item {
    display: flex;
    gap: 10px;

    span {
      color: black;
    }
  }
}

.button-header-question {
  color: #c59c7e;
  font-size: 1.1em;

  &:hover {
    color: #146492;
  }

  &__offcanvas {
    display: contents;
    color: #c59c7e;
    font-size: 1.3em;
  }
}

.mobile-item__dropdown {
  padding: 0;
  display: flex;
  list-style: none;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid $gray-3;
  border-radius: 4px;
  max-width: 200px;
  z-index: 100;
  //position: absolute;

  & li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    white-space: nowrap;
  }

  & li:hover {
    background-color: $gray-4;
    color: #fff;
  }

  @media all and (min-width: 991px) {
    display: none;
  }
}

.counter {
  position: relative;

  span {
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 18px;
    height: 18px;
    background-color: #146492;
    color: #fff;
    font-family: "Gilroy";
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 100;
  }
}

.button-responses {
  color: $color-1;
  &:hover {
    color: $color-2;
  }
}

.card-page__ymaps {
  width: 100%;
  height: 350px;
}

.main-page {
  // .main-page__ymaps
  &__ymaps {
    width: 100%;
    height: 450px;
  }

  // .main-page__offcanvas-cards
  &__offcanvas-cards {
    height: 100%;
  }
}

.response-card-ad {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
  padding: 1em;
  background: #ffffff;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12));
  .photo {
    img {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      display: block;
      @media (max-width: 1199px) {
        width: 140px;
        height: 140px;
      }
      @media (max-width: 767px) {
        width: 120px;
        height: 120px;
      }
    }
  }

  .desc {
    .text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.slider-in-responses {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .swiper-responses {
    display: flex;
    width: inherit;
    height: 350px;
  }
}

.commercial {
  word-break: break-word;
}
